import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebaseVariables from "../firebase.config";
import { log_error, User } from "../HelperFunctions";
import Footer from "../ReusableComponents/Footer";
import Navbar from "../ReusableComponents/navbar";
import Separator from "../ReusableComponents/Separator";
import UserControl from "./components/UserControl";
import UserInfo from "./components/UserInfo";

const AdminView = () => {

    const [userInfo, setUserInfo] = useState(new User())

    const getUserInfo = () => {
        const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), firebaseVariables.firebaseAuth.currentUser.uid.toString())
        getDoc(db_reference).then((user) => {
            if(user.exists()) {
                const data = user.data()
                const userData = new User(data.active, data.city, data.companyName, data.companyRole, data.country, data.currency, data.email, data.job, data.name, data.price, data.profilePicURL, data.timestamp, data.userID, data.userType, data.workExperience, data.telephone===undefined?'':data.telephone).getUser()
                setUserInfo(userData)
            }
        }).catch((error) => {
            log_error(error.code, error.message)
        })
    }

    useEffect(() => {
        setTimeout(() => {
            getUserInfo()
        }, 500);
    }, [])

    return<>
        <div className="container-fluid backgroundImageOpaque" style={{ position: 'fixed', bottom:0, top:0, left:0, right:0 }}>
            <Navbar/>
            <Separator size={'MEDIUM'} />
            <div className="container" style={{ overflowY: 'auto', position: 'fixed', bottom: 90, top: 90, right: 10, left: 10 }} >
                <div className="row" >
                    <div className="col-3">
                        <UserControl userInfo={userInfo}/>
                    </div>
                    <div className="col" style={{ marginLeft:32 }}>
                        <UserInfo userInfo={userInfo}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </>
}

class AdminScreen extends React.Component {
    render(){
        return <AdminView/>
    }
}

export default AdminScreen