import React from "react";

// CSS
import '../../../App.css'

const Links = ({title, onClick = () => {}, icon = ''}) => {
    return <button type="button" className='btn navbar-text my-auto' onClick={onClick} >
        {icon !== ''
        ?
        <i className={icon}></i>
        :
        title
        }
    </button>
}

export default Links