import React, { useEffect, useState } from "react";
import { check_email, get_localized_string, LoadingModal, log_error } from "../../HelperFunctions";
import Banner from "../../ReusableComponents/Banner";
import { colors } from "../../ReusableComponents/Colors";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import Separator from "../../ReusableComponents/Separator";
import Label from "../../ReusableComponents/Label";
import Input from "../../ReusableComponents/Input";
import OrangeButton from "../../ReusableComponents/OrangeButton";
import firebaseVariables from "../../firebase.config";
import { signInWithEmailAndPassword, updateEmail } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";

const ChangeEmailModal = ({onClose=()=>{}}) => {

    const localized_strings = useState(get_localized_string())

    const [isLoadingOpen, setLoadingOpen] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const [passwordError, setPasswordError] = useState(false)
    const [newEmailError, setNewEmailError] = useState(false)

    const handleChange = () => {
        setPasswordError(false)
        setNewEmailError(false)

        const email = document.getElementById('oldEmailInput').value.toString().trim()
        const password = document.getElementById('passwordInput').value.toString().trim()
        const newEmail = document.getElementById('newEmailInput').value.toString().trim()

        if(password === '' || !check_email(newEmail)) {
            setPasswordError(true)
            handleBanner(localized_strings[0].change_email_error, 'error', 3000)
            return
        }

        if (newEmail === email) {
            setNewEmailError(true)
            handleBanner(localized_strings[0].same_email, 'error', 3000)
            return
        }

        setLoadingOpen(true)
        signInWithEmailAndPassword(firebaseVariables.firebaseAuth, email, password).then((user) => {

            updateEmail(user.user, newEmail).then(() => {

                const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), user.user.uid.toString())
                updateDoc(db_reference, {
                    email: user.user.email
                }).then(() => {

                    setLoadingOpen(false)
                    handleBanner(localized_strings[0].email_update_success, 'success', 3000)
                    setTimeout(() => {
                        onClose()
                        window.location.reload()
                    }, 1000);

                }).catch((anotherError) => {
                    log_error(anotherError.code, anotherError.message)
                    updateEmail(user.user, email)
                    setLoadingOpen(false)
                    handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                })

            }).catch((newError) => {
                log_error(newError.code, newError.message)
                setLoadingOpen(false)
                handleBanner(localized_strings[0].something_wrong, 'error', 3000)
            })

        }).catch((error) => {
            log_error(error.code, error.message)
            setLoadingOpen(false)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    useEffect(() => {
        document.getElementById('oldEmailInput').value = firebaseVariables.firebaseAuth.currentUser.email
    }, [])

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible} />
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
            <ModalHeader title={localized_strings[0].change_email} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start', paddingLeft: 20, paddingRight: 20}}>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].email + ':'} textAlign="start"/>
            <Input type="email" id='oldEmailInput' onError={false} disbled={true}/>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].password + ':'} textAlign="start"/>
            <Input type="password" id='passwordInput' onError={passwordError} />
            <Separator size='SMALL' />

            <Label title={localized_strings[0].new_email + ':'} textAlign="start"/>
            <Input type="email" id='newEmailInput' onError={newEmailError}/>
            <Separator size='SMALL' />

            <OrangeButton title={localized_strings[0].save} onClick={handleChange} />

            <Separator size='SMALL' />
        </div>
        <LoadingModal open={isLoadingOpen}/>
    </>
}

export default ChangeEmailModal