import React, { useState } from "react"

// Reusable components
import Links from "./NavbarComponents/Links"

// CSS
import '../../App.css'

// Helper
import { get_localized_string } from "../HelperFunctions"
import { colors } from "./Colors"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Navbar = () => {

    const localized_strings = useState(get_localized_string())

    const history = useHistory()

    const goToSearch = () => {
        history.push('/search')
    }

    const handleJobOffer = () => {
        history.replace('/offers')
    }

    return<>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column' }} >
            <div style={{ backgroundColor: colors.blue, display: 'inline-block', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 5, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                <Links title={localized_strings[0].search_employee} onClick={goToSearch}/>
                <Links title={localized_strings[0].view_job_offers} onClick={handleJobOffer}/>
            </div>
        </div>
    </>
}

export default Navbar