import React, { useState } from "react";
import { get_localized_string, LoadingModal } from "../../HelperFunctions";
import { colors } from "../../ReusableComponents/Colors";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import Separator from "../../ReusableComponents/Separator";
import BodySpan from "../../ReusableComponents/BodySpan";
import Header from "../../ReusableComponents/Header";

const ContactModal = ({userInfo, onClose=()=>{}}) => {

    const localized_strings = useState(get_localized_string())

    return<>
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
            <ModalHeader title={localized_strings[0].contact} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start'}}>
            <Separator size='SMALL' />
            <Header title={localized_strings[0].do_contact + userInfo.name + '?'} fontSize={'SMALL'}/>
            <Separator size='SMALL' />
            <div style={{ paddingRight: 20, paddingLeft: 20}}>
                <BodySpan bodyText={localized_strings[0].contact_email + ': ' } spanText={userInfo.email} spanColor={colors.blue} spanWeight='900'/>
                <BodySpan bodyText={localized_strings[0].contact_telephone + ': ' } spanText={userInfo.telephone} spanColor={colors.blue} spanWeight='900'/>
            </div>
            <Separator size='SMALL' />
        </div>
    </>
}

export default ContactModal