import React from "react";

const Header = ({title, fontSize, textAlignment, textColor, padding}) => {

    // Text
    const handleFontSize = () => {
        const size = fontSize
        switch (size) {
            case 'SMALL':
                return 20
            case 'MEDIUM':
                return 24;
            case 'BIG':
                return 30;
            default:
                return 40;
        }
    }
    const handlePadding = () => {
        const size = padding
        switch (size) {
            case 'SMALL':
                return 8;
            case 'MEDIUM':
                return 16;
            case 'BIG':
                return 24;
            default:
                return 4
        }
    }

    return<>
        <p className="my-auto" style={{ fontSize: handleFontSize(), width: '100%', textAlign: textAlignment, color: textColor, padding: handlePadding() }} >{title}</p>
    </>
}

export default Header