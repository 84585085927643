import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

// Firebase initialization
const firebaseConfig = {
    apiKey: "AIzaSyCpSi9tCK7G4dIq8Q7CIurSyLVi_RTz6kQ",
    authDomain: "hephaestus-97800.firebaseapp.com",
    projectId: "hephaestus-97800",
    storageBucket: "hephaestus-97800.appspot.com",
    messagingSenderId: "158998435117",
    appId: "1:158998435117:web:a523a91a807075b7586fa4",
    measurementId: "G-LF5KZJG93M"
};

initializeApp(firebaseConfig)

const analyticsApp = getAnalytics()
const firestoreApp = getFirestore()
const firebaseAuth = getAuth()
const firebaseStorage = getStorage()
const firestorePath = 'production'
//const firestorePath = 'development'

const firebaseVariables = {
    analyticsApp,
    firestoreApp, 
    firebaseAuth,
    firebaseStorage,
    firestorePath
}

export default firebaseVariables