import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Login from "./Login";

const UserInteraction = () => {

    const history = useHistory()

    const goToRegister = () => {
        history.push('/register')
    }

    return<>
        <div className="round_corner_container">
            <Login onClick={goToRegister} />
        </div>
    </>
}

export default UserInteraction