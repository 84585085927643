import React from "react";
import defaultUserPic from '../../assests/defaultUser.png'

const ImageView = ({imageURL, cornerRadius = 5, size = 'SMALL'}) => {

    const handleSize = () => {
        switch (size) {
            case 'SMALL':
                return 150;
            case 'MEDIUM':
                return 200;
            case 'BIG':
                return 300;
            default:
                return 100;
        }
    }

    return<>
        <img style={{ height: handleSize(), width: handleSize(), objectFit:'cover', marginBottom: 20, borderRadius: cornerRadius }} alt='profile'
        src={imageURL===undefined||imageURL===''?defaultUserPic:imageURL}/>
    </>
}

export default ImageView