import React from "react";

const BlueButton = ({title='', size = '100%', onClick = () =>{}, icon='', disabled = false}) => {

    return<>
        <button type='button' className='btn btn-blue' style={{ width: size, fontWeight: 'bold' }} onClick={onClick} disabled={disabled} >
            {
                title==='' && icon !==''
                ?
                <i className={icon}></i>
                :
                title
            }
        </button>
    </>
}

export default BlueButton