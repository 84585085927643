import React, { useEffect, useState } from 'react'

// Router
import { BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth';
import firebaseVariables from './components/firebase.config';

// Views
import HomeView from './components/Home/Home';
import PrivacyPolicyComponent from './components/Privacy/PrivacyPolicyComponent';
import RegisterView from './components/Register/Register';
import AdminScreen from './components/Admin/Admin';
import SearchOffView from './components/Search/SearchOff';
import SearchOnView from './components/Search/SearchOn';
import JobOfferOnView from './components/JobOffer/JobOfferOn';
import JobOfferOffView from './components/JobOffer/JobOfferOff';
import NewJobOfferView from './components/JobOffer/NewJobOffer';
import TutorialScreen from './components/Tutorial/TutorialScreen';

import MobileView from './components/Mobile/Mobile';

function App() {

  const [deviceType] = useState(getDevice())
  const [logged, setLogged] = useState()
  function getDevice() {
      let userAgent = window.navigator.userAgent.toUpperCase()
      if(userAgent.includes('MOBILE')) {
          return 'mobile'
      } else {
          return 'pc'
      }
  }

  const checkRoute = () => {
    onAuthStateChanged(firebaseVariables.firebaseAuth, (user) => {
      if (user) {
        setLogged(true)
        return
      } 
      setLogged(false)
    })
  }

  useEffect(() => {
    checkRoute()
  }, [])

  return (
    <Router>
      {
        deviceType === 'mobile'
        ?
        <Switch>
          <Route exact path='/' render={() => (<Redirect to='/mobile-view' />)} />
          <Route exact path='/home' render={() => (<Redirect to='/mobile-view' />)} />
          <Route exact path='/register' render={() => (<Redirect to='/mobile-view' />)} />
          <Route exact path='/admin' render={() => (<Redirect to='/mobile-view' />)} />
          <Route exact path='/new-offer' render={() => (<Redirect to='/mobile-view' />)} />
          <Route exact path='/search' render={() => (<Redirect to='/mobile-view' />)} />
          <Route exact path='/offers' render={() => (<Redirect to='/mobile-view' />)} />
          <Route exact path='/tutorial' render={() => (<Redirect to='/mobile-view' />)} />
          <Route path='/mobile-view' component={MobileView} />
          <Route path='/privacy' component={PrivacyPolicyComponent} />
        </Switch>
        :
        logged
        ?
        <Switch>
          <Route exact path='/' render={() => (<Redirect to='/admin' />)} />
          <Route exact path='/home' render={() => (<Redirect to='/admin' />)} />
          <Route exact path='/register' render={() => (<Redirect to='/admin' />)} />
          <Route exact path='/mobile-view' render={() => (<Redirect to='/admin' />)} />
          <Route path='/privacy' component={PrivacyPolicyComponent} />
          <Route path='/admin' component={AdminScreen} />
          <Route path='/search' component={SearchOnView} />
          <Route path='/offers' component={JobOfferOnView} />
          <Route path='/new-offer' component={NewJobOfferView} />
          <Route path='/tutorial' component={TutorialScreen} />
        </Switch>
        :
        <Switch>
          <Route exact path='/' render={() => (<Redirect to='/home' />)} />
          <Route exact path='/admin' render={() => (<Redirect to='/home' />)} />
          <Route exact path='/new-offer' render={() => (<Redirect to='/home' />)} />
          <Route exact path='/mobile-view' render={() => (<Redirect to='/home' />)} />
          <Route exact path='/tutorial' render={() => (<Redirect to='/home' />)} />
          <Route path='/home' component={HomeView} />
          <Route path='/privacy' component={PrivacyPolicyComponent} />
          <Route path='/register' component={RegisterView} />
          <Route path='/search' component={SearchOffView} />
          <Route path='/offers' component={JobOfferOffView} />
        </Switch>
      }
    </Router>
  );
}

export default App;
