import React, { useState } from "react";
import { get_localized_string } from "../HelperFunctions";
import AndroidButton from "../Home/components/AndroidButton";
import AppleButton from "../Home/components/AppleButton";
import Video from "../Home/components/Video";
import { colors } from "../ReusableComponents/Colors";
import Footer from "../ReusableComponents/Footer";
import Header from "../ReusableComponents/Header";
import LogoFull from "../ReusableComponents/LogoFull";

const MobileComponent = () => {
    const localized_strings = useState(get_localized_string())
    return<>
        <div className="container-fluid backgroundImageOpaque" style={{ position: 'fixed', bottom:0, top:0, left:0, right:0 }}>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: "column", position: 'fixed', bottom:80, top:0, left:0, right:0, overflowY: "auto"}}>
                <LogoFull size={'SMALL'}/>
                <div style={{ height: 300, width: '100%'}} >
                    <Video url="ui-BY6bAlL8"/>
                </div>
                <br></br>
                <Header title={localized_strings[0].download_app_body} fontSize={'SMALL'} textAlignment={'center'} textColor={colors.blue} padding={'SMALL'}/>
                <br></br>
                <AppleButton/>
                <br></br>
                <AndroidButton/>
            </div>
            <Footer/>
        </div>
    </>
}

class MobileView extends React.Component {
    render() {
        return <MobileComponent/>
    }
}

export default MobileView