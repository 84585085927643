import React, { useState } from "react";
import { get_localized_string } from "../HelperFunctions";
import { colors } from "../ReusableComponents/Colors";
import ModalHeader from "../ReusableComponents/ModaleHeader";
import Separator from "../ReusableComponents/Separator";
import ImageView from "../ReusableComponents/ImageView"
import androidqr from '../../assests/androidqr.png'
import iosqr from '../../assests/iosqr.png'
import AndroidButton from "../Home/components/AndroidButton";
import AppleButton from "../Home/components/AppleButton";
import logo from '../../assests/logo_full_trans.png'

const ModalDownload = ({onClose = () => {}}) => {

    const localized_strings = useState(get_localized_string());

    return <>
        <img style={{ width: '100%', height: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, objectFit: 'contain'}} alt='profile' src={logo}/>        
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10, zIndex: 2, position: 'relative'}} >
            <ModalHeader title={localized_strings[0].download_app} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column'}}>
            <div style={{ zIndex: 2, backgroundColor: '#FFFFFFCC'}}>
                <Separator size='SMALL' />
                <h4 style={{ textAlign: 'center', zIndex: 2}}>{localized_strings[0].download_app_body}</h4>
                <Separator size='SMALL' />
                <div style={{ marginLeft: 10, marginRight: 10, display: 'flex', flexDirection: 'row', zIndex: 2}}>
                    <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                        <h3 style={{ color: colors.green, fontWeight: 'bold'}}>Android</h3>
                        <ImageView imageURL={androidqr}/>
                        <AndroidButton/>
                    </div>
                    <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                        <h3 style={{ color: colors.darkGray, fontWeight: 'bold'}}>iOS</h3>
                        <ImageView imageURL={iosqr}/>
                        <AppleButton/>
                    </div>
                </div>
                <Separator size='SMALL' />
            </div>
        </div>
    </>
}

export default ModalDownload;