import React, { useState } from "react";
import { check_email, get_localized_string, LoadingModal, log_error } from "../../HelperFunctions";
import Banner from "../../ReusableComponents/Banner";
import Input from "../../ReusableComponents/Input";
import Label from "../../ReusableComponents/Label";
import Logo from "../../ReusableComponents/Logo";
import OrangeButton from "../../ReusableComponents/OrangeButton";
import Separator from "../../ReusableComponents/Separator";

// Firebase
import firebaseVariables from '../../firebase.config'
import { sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import ControlButtons from "../../Admin/components/ControlButtons";

const Login = ({onClick = () => {}}) => {

    const localized_strings = useState(get_localized_string())
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)

    const [isLoadingOpen, setLoadingOpen] = useState(false)

    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }
    
    const signout = () => {
        signOut(firebaseVariables.firebaseAuth).catch((error) => {
            log_error(error.code, error.message)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    const loginFunction = () => {
        setEmailError(false)
        setPasswordError(false)

        const email = document.getElementById('loginEmailInput').value.toString().trim()
        const password = document.getElementById('loginPasswordInput').value.toString().trim()

        if(email !== '' && password !== '') {
            setLoadingOpen(true)
            signInWithEmailAndPassword(firebaseVariables.firebaseAuth, email, password).then((user) => {

                const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), user.user.uid.toString())
                getDoc(db_reference).then((document) => {
                    const data = document.data()
                    if(data.active){
                        setLoadingOpen(false)
                        handleBanner(localized_strings[0].welcome_message, 'success', 3000)
                    } else {
                        setLoadingOpen(false)
                        handleBanner(localized_strings[0].not_active_user, 'error', 10000)
                        signout()
                    }
                })

            }).catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                setLoadingOpen(false)
                
                switch (errorCode) {
                    case 'auth/user-not-found':
                        handleBanner(localized_strings[0].not_signed_user, 'error', 3000)
                        setEmailError(true)
                        setPasswordError(true)
                        break;

                    case 'auth/wrong-password':
                        handleBanner(localized_strings[0].wrong_credentials, 'error', 3000)
                        setPasswordError(true)
                        break;
                
                    default:
                        handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                        log_error(errorCode, errorMessage)
                        break;
                }
                
            })
        } else {
            handleBanner(localized_strings[0].invalid_credentials, 'error', 3000)
            if (email === '') {
                setEmailError(true)
            }
            if (password === '') {
                setPasswordError(true)
            }
        }

    }

    const handleForgotPassword = () => {
        setEmailError(false)

        const email = document.getElementById('loginEmailInput').value.toString().trim()

        if (email === '' || !check_email(email)) {
            handleBanner(localized_strings[0].forgot_pass_email, 'error', 3000);
            setEmailError(true)
            return
        }

        sendPasswordResetEmail(firebaseVariables.firebaseAuth, email).then(() => {
            handleBanner(localized_strings[0].password_reset_sent, 'success', 3000);
        }).catch((error) => {
            handleBanner(localized_strings[0].something_wrong, 'error', 3000);
            log_error('ResetEmail', error.message)
        })
    }

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible}/>
        <div className="container-fluid">
            <div className="row" >
                <div className="col text-center" >
                    <Logo size={'MEDIUM'} />
                    <Separator/>

                    <Label title={localized_strings[0].email_address + ':'} />
                    <Input type={'email'} id={'loginEmailInput'} onError={emailError}/>

                    <Separator size={'SMALL'} />

                    <Label title={localized_strings[0].password + ':'} />
                    <Input type={'password'} id={'loginPasswordInput'} onError={passwordError}/>
                    <div style={{ display: 'flex'}} >
                        <ControlButtons title={localized_strings[0].forgot_password} onClick={handleForgotPassword} />
                    </div>

                    <Separator size={'SMALL'} />

                    <OrangeButton title={localized_strings[0].login} onClick={loginFunction} />

                    <Separator size={'SMALL'}/>

                    <p style={{ textAlign: 'center', lineHeight: '2' }} >
                        {localized_strings[0].still_no_account}
                        <button type='button' className='btn navbar-text-mobile' style={{ marginLeft: 0, marginRight: 0, padding: 0, paddingBottom: 5 }} onClick={onClick}>{localized_strings[0].click_here}</button>
                    </p>
                </div>
            </div>
        </div>
        <LoadingModal open={isLoadingOpen} />
    </>
}

export default Login