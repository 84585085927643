import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebaseVariables from "../../firebase.config";
import { get_localized_string } from "../../HelperFunctions";
import BodySpan from "../../ReusableComponents/BodySpan";
import { colors } from "../../ReusableComponents/Colors";
import Header from "../../ReusableComponents/Header";
import LineSeparator from "../../ReusableComponents/LineSeparator";
import ModalComponent from "../../ReusableComponents/ModalComponent";
import EditOfferModal from "./EditOfferModal";

const EmployerInfo = ({companyName, companyRole}) => {

    const localized_strings = useState(get_localized_string())

    const [editModalVisible, setEditModalVisible] = useState(false)
    const [selectedOffer, setSelectedOffer] = useState([])

    const getMyOffers = () => {
        let offers = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users/' + firebaseVariables.firebaseAuth.currentUser.uid.toString()), 'offers')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()
                offers.push(data)
            })

            populateOffersList(offers)
        })
    }

    const populateOffersList = (list) => {
        list.reverse()
        let html = ''
        list.forEach(offer => {
            const visibility = offer.visibility==='hidden'?localized_strings[0].hidden:localized_strings[0].visible
            html += '<li class="list-group-item" style="background-color: #FFFFFFAA;">' +
            '<div class="row">' +
                '<div class="col" style="display: flex;">' +
                    '<h6 class="my-auto" style="color: #4588F5;">' + offer.title + ' // ' + localized_strings[0].visibility + ': ' + visibility + '</h6>' +
                '</div>' +
                '<div class="col-2 text-end">' +
                    '<button type="button" id="' + offer.id + '" class="btn plus-button" style="font-size: 16px !important;"><i class="fas fa-pen"></i></button>' +
                '</div>' +
            '</div>' +
            '</li>'
        })

        document.getElementById('offersBody').innerHTML = html

        list.forEach(offer => {
            document.getElementById(offer.id).addEventListener('click',  () => {
                setSelectedOffer(offer)
                setEditModalVisible(true)
            })
        })
    }

    useEffect(() => {
        getMyOffers()
    }, [])

    return<>
        <BodySpan bodyText={localized_strings[0].company_name + ': '} spanText={companyName} spanColor={colors.blue} spanWeight={'bold'} />
        <BodySpan bodyText={localized_strings[0].company_role + ': '} spanText={companyRole} spanColor={colors.blue} spanWeight={'bold'} />
        <Header title={localized_strings[0].view_job_offers} fontSize={'SMALL'} textColor={colors.orange}/>
        <LineSeparator/>
        <ul className="list-group" id="offersBody">
        </ul>
        <ModalComponent visibility={editModalVisible} view={<EditOfferModal onClose={()=>{setEditModalVisible(false)}} offer={selectedOffer}/>}/>
    </>
}

export default EmployerInfo