import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebaseVariables from "../firebase.config";
import { get_localized_string, LoadingModal, log_error } from "../HelperFunctions";
import Banner from "../ReusableComponents/Banner";
import BlueButton from "../ReusableComponents/BlueButton";
import { colors } from "../ReusableComponents/Colors";
import CustomAutocomplete from "../ReusableComponents/CustomAutocomplete";
import Header from "../ReusableComponents/Header";
import Input from "../ReusableComponents/Input";
import Label from "../ReusableComponents/Label";
import Separator from "../ReusableComponents/Separator";
import defaultProfilePhoto from '../../assests/defaultUser.png'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavbarSearch from "../ReusableComponents/NavbarComponents/NavbarSearch";
import ModalComponent from "../ReusableComponents/ModalComponent";
import ContactModal from "./component/ContactModal";
import Footer from "../ReusableComponents/Footer";

const SearchOn = () => {

    const handleHome = () => {
        history.replace('/admin')
    }

    const history = useHistory()
    const localized_strings = useState(get_localized_string())

    const [jobsList, setJobsList] = useState([])

    const [contactModalVisible, setContactModalVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState()

    const [isLoadingOpen, setLoadingOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const getJobList = () => {
        setLoadingOpen(true)
        const ref = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath, 'db')
        getDoc(ref).then((doc) => {
            const data = doc.data()
            setJobsList(data.jobs)
            setLoadingOpen(false)
        }).catch((error) => {
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
            log_error('RetrieveJobSearch', error)
        })
    }

    const getCountries = () => {
        setLoadingOpen(true)
        let countries = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()

                if(data.country!==undefined && data.country!=='' && data.userType==='employee' && data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {
                    if(!countries.includes(data.country)) {
                        countries.push(data.country)
                    }
                }
            })

            let html = '<option value="Country">' + localized_strings[0].country + ' ...</option>'
            countries.forEach((c) => {

                html += '<option value="' + c + '">' + c + '</option>'
                
            })

            document.getElementById('countryInput').innerHTML = html
            setLoadingOpen(false)
        })
    }

    const getCities = (count) => {
        setLoadingOpen(true)
        let cities = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()

                if(data.country===count && data.userType==='employee' && data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {
                    if(!cities.includes(data.city)) {
                        cities.push(data.city)
                    }
                }
            })


            let html = '<option value="City">' + localized_strings[0].city + ' ...</option>'
            cities.forEach((c) => {

                html += '<option value="' + c + '">' + c + '</option>'
                
            })

            document.getElementById('cityInput').innerHTML = html
            document.getElementById('cityInput').disabled = false
            setLoadingOpen(false)

        })
    }

    const onCountryChange = () => {
        const country = document.getElementById('countryInput').value.toString().trim()
        if(country!=='Country') {
            getCities(country)
        } else {
            document.getElementById('cityInput').disabled = true
        }
    
    }

    const defineSearch = () => {
        const job = document.getElementById('jobInput').value.toString().trim()
        const price = document.getElementById('priceInput').value.toString().trim()
        const country = document.getElementById('countryInput').value.toString().trim()
        const cityDisabled = document.getElementById('cityInput').disabled
        const city = document.getElementById('cityInput').value.toString().trim()

        if (job !== '' &&
            (price === '' || price === '0') &&
            country === 'Country' &&
            cityDisabled) {
                simpleSearch(job)
                return
        }

        if (job !== '' && 
            price !== '' &&
            country === 'Country' &&
            cityDisabled &&
            Number.parseFloat(price) > 0.00) {
                searchByPrice(job, price)
                return
        }

        if (job !== '' && 
            price !== '' &&
            country !== 'Country' &&
            city === 'City' &&
            Number.parseFloat(price) > 0.00) {
                searchByPriceCountry(job, price, country)
                return
        }

        if (job !== '' && 
            (price === '' || price === '0') &&
            country !== 'Country' &&
            city === 'City') {
                searchByCountry(job, country)
                return
        }

        if (job !== '' && 
            (price === '' || price === '0') &&
            country !== 'Country' &&
            !cityDisabled &&
            city !== 'City') {
                searchByCountryCity(job, country, city)
                return
        }

        if (job !== '' && 
            price !== '' &&
            country !== 'Country' &&
            !cityDisabled &&
            city !== 'City' &&
            Number.parseFloat(price) > 0.00) {
                searchByAll(job, price, country, city)
                return
        }

        handleBanner(localized_strings[0].no_params_no_search, 'error', 3000)
    }

    const simpleSearch = (job) => {
        setLoadingOpen(true)

        let wl = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach(user => {
                const data = user.data()

                if(data.userType === 'employee' && 
                    data.active === true && 
                    data.job === job &&
                    data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {

                        wl.push(data)

                }
            })
            populateWorkersList(wl)
        })

    }

    const searchByPrice = (job, price) => {
        setLoadingOpen(true)

        let wl = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach(user => {
                const data = user.data()

                if(data.userType === 'employee' && 
                    data.active === true &&
                    data.job === job &&
                    data.price !== undefined &&
                    Number.parseFloat(data.price) <= Number.parseFloat(price) &&
                    data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {

                        wl.push(data)

                }
            })
            populateWorkersList(wl)
        })

    }

    const searchByPriceCountry = (job, price, country) => {
        setLoadingOpen(true)

        let wl = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach(user => {
                const data = user.data()

                if(data.userType === 'employee' && 
                    data.active === true && 
                    data.job === job &&
                    data.price !== undefined &&
                    Number.parseFloat(data.price) <= Number.parseFloat(price) &&
                    data.country !== undefined &&
                    data.country.toUpperCase() === country.toUpperCase() &&
                    data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {

                        wl.push(data)

                }
            })
            populateWorkersList(wl)
        })

    }

    const searchByCountry = (job, country) => {
        setLoadingOpen(true)

        let wl = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach(user => {
                const data = user.data()

                if(data.userType === 'employee' && 
                    data.active === true && 
                    data.job === job &&
                    data.country !== undefined &&
                    data.country.toUpperCase() === country.toUpperCase() &&
                    data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {

                        wl.push(data)

                }
            })
            populateWorkersList(wl)
        })

    }

    const searchByCountryCity = (job, country, city) => {
        setLoadingOpen(true)

        let wl = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach(user => {
                const data = user.data()

                if(data.userType === 'employee' && 
                    data.active === true && 
                    data.job === job &&
                    data.city !== undefined &&
                    data.country !== undefined &&
                    data.city.toUpperCase() === city.toUpperCase() &&
                    data.country.toUpperCase() === country.toUpperCase() &&
                    data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {

                        wl.push(data)

                }
            })
            populateWorkersList(wl)
        })

    }

    const searchByAll = (job, price, country, city) => {
        setLoadingOpen(true)

        let wl = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'users')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach(user => {
                const data = user.data()

                if(data.userType === 'employee' && 
                    data.active === true && 
                    data.job === job &&
                    data.city !== undefined &&
                    data.country !== undefined &&
                    data.price !== undefined &&
                    data.city.toUpperCase() === city.toUpperCase() &&
                    data.country.toUpperCase() === country.toUpperCase() &&
                    Number.parseFloat(data.price) <= Number.parseFloat(price) &&
                    data.userID !== firebaseVariables.firebaseAuth.currentUser.uid.toString()) {

                        wl.push(data)

                }
            })
            populateWorkersList(wl)
        })

    }

    const populateWorkersList = (workersList) => {
        document.getElementById('workersBody').innerHTML = ''
        setLoadingOpen(false)

        if (workersList.length === 0) {
            handleBanner(localized_strings[0].no_worker_params, 'error', 3000)
            return
        }

        let html = ''

        workersList.forEach(worker => {

            const name = worker.name
            const workExperience = worker.workExperience===undefined || worker.workExperience===''?'None':worker.workExperience
            const price = worker.price===undefined || worker.price===''?'NaN':worker.price
            const currency = worker.currency===undefined || worker.currency===''?'€':worker.currency

            const img = worker.profilePicURL===undefined||worker.profilePicURL===''?defaultProfilePhoto:worker.profilePicURL

            const email = worker.email

            html += '<li class="list-group-item" >' +
            '<div class="row" style="margin-top: 10px; margin-bottom: 10px;" >' +
            '<div class="col-md-4 col-xl-3" >' +
            '<img style="height: 200px; border-radius: 10px; max-width: 200px;" alt="profile" src="' + img + '"/>' +
            '</div>' +
            '<div class="col" >' +
            '<p style="color: #4588F5; font-weight: bold;" >' + name + '</p>' + 
            '<p>' + workExperience + '</p>' + 
            '<p>' + price + ' ' + currency + '/hour</p>' + 
            '<div class="text-end" >' +
            '<button type="button" class="btn btn-orange" style="padding-left: 40px; padding-right: 40px;" id="' + worker.userID + '">' + localized_strings[0].contact +'</button>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</li>'
        });

        document.getElementById('workersBody').innerHTML = html

        workersList.forEach(worker => {
            document.getElementById(worker.userID).addEventListener('click', () => {
                const su = {
                    name: worker.name,
                    email: worker.email,
                    telephone: worker.telephone===undefined?'':worker.telephone
                }
                setSelectedUser(su)
                setContactModalVisible(true)
            })
        })
    }

    useEffect(() => {
        getJobList()
        getCountries()
    }, [])

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible}/>

        <div className="container-fluid backgroundImageOpaque" style={{ position: 'fixed', bottom:0, top:0, left:0, right:0 }} >
            <NavbarSearch handleHome={handleHome}/>
            
            <Separator size={'MEDIUM'}/>

            <div className="row" >

                <div className="col-4">
                    <div className="round_corner_container">
                        <div style={{ backgroundColor: colors.orange, borderTopLeftRadius: 10, borderTopRightRadius: 10}} >
                            <Header title={localized_strings[0].filters} fontSize={'MEDIUM'} textAlignment={'start'} textColor={'white'}/>
                        </div>
                        <Separator size={'SMALL'}/>
                        <div  style={{ paddingLeft:10, paddingRight: 10 }}>
                            <Label title={localized_strings[0].job + ':'}/>
                            <CustomAutocomplete list={jobsList} id='jobInput' placeholder={localized_strings[0].programmer}/>
                            <Separator size={'SMALL'}/>

                            <Label title={localized_strings[0].max_price_x_hour + ':'}/>
                            <Input type="number" id='priceInput' autoCapitalize="none" onError={false}/>
                            <Separator size={'SMALL'}/>

                            <select className="form-select" id="countryInput" onChange={onCountryChange}>
                            </select>
                            <Separator size={'SMALL'}/>


                            <select className="form-select" id="cityInput" disabled>
                                <option value="City">{localized_strings[0].city} ...</option>
                            </select>
                            <Separator size={'SMALL'}/>

                            <BlueButton icon="fas fa-search" onClick={defineSearch}/>
                            <Separator size={'SMALL'}/>

                        </div>
                    </div>
                </div>

                <div className="col">
                    <ul className='list-group' id='workersBody' style={{ overflowY: 'auto', position: 'fixed', bottom: 90, top: 105, right: 10, width: '65%' }}/>
                </div>
            </div>
            <Footer/>
        </div>
        <LoadingModal open={isLoadingOpen}/>
        <ModalComponent visibility={contactModalVisible} view={<ContactModal userInfo={selectedUser} onClose={()=>{setContactModalVisible(false)}} />}/>
    </>
}

class SearchOnView extends React.Component {
    render(){
        return <SearchOn />
    }
}

export default SearchOnView