import React from "react";

const BodySpan = ({bodyText, spanText, spanColor, spanWeight}) => {
    return <>
        <p>
            {bodyText}<span style={{ fontWeight:spanWeight, color: spanColor }} >{spanText}</span>
        </p>
    </>
}

export default BodySpan