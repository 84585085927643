import React from "react";

const AppleButton = () => {
    return<>
    <a className="btn apple-button" target='_blank' href="https://apps.apple.com/es/app/hephaestus-build-your-future/id1599338084">
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex', marginRight: 10}}>
                <i className="fab fa-apple" style={{fontSize: 40}}></i>
            </div>
            <div style={{ flex: 1, alignItems: 'flex-start', display: 'flex', marginLeft: 10}}>
                <p className="my-auto" >Apple Store</p>
            </div>
        </div>
    </a>
    </>
}

export default AppleButton