import React from "react";
import { colors } from "./Colors";
import SocialButton from "./FooterComponents/SocialButton";

const Footer = () => {
    return<>
    <div style={{ width: '100%', bottom: 0, position: 'fixed', display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
        <div style={{ backgroundColor: colors.blue, display: 'inline-block',borderTopRightRadius: 10, borderTopLeftRadius: 10, paddingLeft: 20, paddingRight: 20 }} >
            <div className='row text-center'>
                <div className='col' >
                    <SocialButton url={'https://www.facebook.com/profile.php?id=100054117795577'} icon={'fab fa-facebook'} />
                    <SocialButton url={'https://www.instagram.com/hephaestus.online/'} icon={'fab fa-instagram'} />
                    <SocialButton url={'https://twitter.com/hephaestus_2021'} icon={'fab fa-twitter'} />
                    <SocialButton url={'https://discord.gg/T4k9nDzcgQ'} icon={'fab fa-discord'} />
                    <SocialButton url={'mailto:egel.development@gmail.com'} icon={'far fa-envelope'} />
                </div>
            </div>
        </div>
    </div>
    </>
}

export default Footer