import React from "react";

const Video = ({url}) => {
    return<>
        <div  style={{ width: '100%', height: '100%', paddingTop:10, paddingBottom: 10 }}>
            <iframe
            width="100%"
            height="100%"
            src={'https://www.youtube.com/embed/' + url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ borderRadius: 10}}/>
        </div>
    </>
}

export default Video