import React from "react";

const ControlButtons = ({title, onClick = () => {}, color = 'blue'}) => {

    const colorClass = color==='red'?'btn btn-transparent-red':'btn profile-transparent-button'

    return<>
        <button type='button' className={colorClass} onClick={onClick} >
            {title}
        </button>
    </>
}

export default ControlButtons