import React, { useState } from "react";
import { get_localized_string } from "../../HelperFunctions";
import BodySpan from "../../ReusableComponents/BodySpan";
import { colors } from "../../ReusableComponents/Colors";

const EmployeeInfo = ({price, currency}) => {

    const localized_strings = useState(get_localized_string())
    const priceText = price === undefined || price === ''?'0,0':price
    const currencyText = currency === undefined || currency === ''?'€':currency
    const text = priceText + ' ' + currencyText

    return<>
        <BodySpan bodyText={localized_strings[0].price_x_hour + ': '} spanText={text} spanColor={colors.blue} spanWeight={'bold'} />
    </>
}

export default EmployeeInfo