import React, { useState } from "react";
import { get_localized_string } from "../../HelperFunctions";
import Body from "../../ReusableComponents/Body";
import BodySpan from "../../ReusableComponents/BodySpan";
import { colors } from "../../ReusableComponents/Colors";
import Header from "../../ReusableComponents/Header";
import LineSeparator from "../../ReusableComponents/LineSeparator";
import EmployeeInfo from "./EmployeeInfo";
import EmployerInfo from "./EmployerInfo";

const renderUser = (userType, info) => {
    if (userType === 'employee') {
        return <EmployeeInfo price={info.price} currency={info.currency}/> 
    }
    return <EmployerInfo companyName={info.companyName} companyRole={info.companyRole}/>
}

const UserInfo = ({userInfo}) => {

    const localized_strings = useState(get_localized_string())

    return<>
        <Header title={localized_strings[0].general_info} textColor={colors.orange} fontSize={'MEDIUM'}/>
        <LineSeparator/>

        <BodySpan bodyText={localized_strings[0].full_name + ': '} spanText={userInfo.name} spanColor={colors.blue} spanWeight={'bold'} />
        <BodySpan bodyText={localized_strings[0].email + ': '} spanText={userInfo.email} spanColor={colors.blue} spanWeight={'bold'} />
        <BodySpan bodyText={localized_strings[0].telephone + ': '} spanText={userInfo.telephone} spanColor={colors.blue} spanWeight={'bold'} />
        <BodySpan bodyText={localized_strings[0].country + ': '} spanText={userInfo.country} spanColor={colors.blue} spanWeight={'bold'} />
        <BodySpan bodyText={localized_strings[0].city + ': '} spanText={userInfo.city} spanColor={colors.blue} spanWeight={'bold'} />
        <BodySpan bodyText={localized_strings[0].userID + ': '} spanText={userInfo.userID} spanColor={colors.blue} spanWeight={'bold'} />
        {renderUser(userInfo.userType, userInfo)}

        <Header title={localized_strings[0].work_experience} textColor={colors.orange} fontSize={'MEDIUM'}/>
        <LineSeparator/>
        <Body text={userInfo.workExperience} />
    </>
}

export default UserInfo