import React from "react";
import { colors } from "./Colors";

const Banner = ({isVisible, message, type}) => {

    const handleType = () => {
        const t = type
        switch (t) {
            case 'error':
                return colors.red;
            case 'success':
                return colors.green;
            default:
                return colors.yellow;
        }
    }

    return<>
        <div style={{ height: 0, visibility: isVisible?'visible':'hidden', position: 'relative', paddingLeft: 20, paddingRight: 20 }}>
            <div className="container">
                <br></br>
                <div className="row">
                    <div className="col my-auto" style={{ backgroundColor: handleType(), borderRadius: 10 }} >
                        <p className="my-auto" style={{ padding: 8, color: 'white', fontWeight: 'bold' }}>{message}</p>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Banner