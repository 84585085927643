import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import firebaseVariables from "../../firebase.config";
import { check_email, check_password, get_localized_string, LoadingModal, log_error, User } from "../../HelperFunctions";
import Banner from "../../ReusableComponents/Banner";
import Input from "../../ReusableComponents/Input";
import Label from "../../ReusableComponents/Label";
import OrangeButton from "../../ReusableComponents/OrangeButton";
import Separator from "../../ReusableComponents/Separator";

const EmployerRegister = () => {

    const history = useHistory()

    const localized_strings = useState(get_localized_string())
    const [isLoadingOpen, setLoadingOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [secondPasswordError, setSecondPasswordError] = useState(false)
    const [comapnyNameError, setCompanyNameError] = useState(false)
    const [companyRoleError, setCompanyRoleError] = useState(false)
    const [countryError, setCountryError] = useState(false)
    const [cityError, setCityError] = useState(false)


    const register = () => {
        setNameError(false)
        setEmailError(false)
        setPasswordError(false)
        setSecondPasswordError(false)
        setCompanyNameError(false)
        setCompanyRoleError(false)
        setCountryError(false)
        setCityError(false)

        const username = document.getElementById('nameInput').value.toString().trim()
        const email = document.getElementById('emailInput').value.toString().trim()
        const password = document.getElementById('passwordInput').value.toString().trim()
        const repPassword = document.getElementById('secondPasswordInput').value.toString().trim()
        const country = document.getElementById('countryInput').value.toString().trim()
        const city = document.getElementById('cityInput').value.toString().trim()
        const companyName = document.getElementById('companyNameInput').value.toString().trim()
        const companyRole = document.getElementById('companyRoleInput').value.toString().trim()

        if(username === '') {
            setNameError(true)
            handleBanner(localized_strings[0].name_error, 'error', 2000)
            return
        }

        if (email === '' || !check_email(email)) {
            setEmailError(true)
            handleBanner(localized_strings[0].email_error, 'error', 2000)
            return
        }

        if (!check_password(password)) {
            setPasswordError(true)
            handleBanner(localized_strings[0].password_not_valid, 'error', 5000)
            return
        }

        if (repPassword !== password) {
            setPasswordError(true)
            setSecondPasswordError(true)
            handleBanner(localized_strings[0].password_different, 'error', 3000)
            return
        }

        if(country === '') {
            setCountryError(true)
            handleBanner(localized_strings[0].country_empty_error, 'error', 2000)
            return
        }
        if(city === '') {
            setCityError(true)
            handleBanner(localized_strings[0].city_empty_error, 'error', 2000)
            return
        }

        if (companyName === '') {
            setCompanyNameError(true)
            handleBanner(localized_strings[0].company_name_error, 'error', 2000)
            return
        }

        if (companyRole === '') {
            setCompanyRoleError(true)
            handleBanner(localized_strings[0].company_role_error, 'error', 2000)
            return
        }

        setLoadingOpen(true)

        createUserWithEmailAndPassword(firebaseVariables.firebaseAuth, email, password).then((userCredentials) => {

            const user = new User(true, city, companyName, companyRole, country, '€', email, '', username, '', '', + new Date()/100, userCredentials.user.uid.toString(), 'employer', '', '').getUser()

            const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), user.userID);
            setDoc(db_reference, user).then(() => {

                setLoadingOpen(false)
                handleBanner(localized_strings[0].welcome_message, 'success', 2000)
                setTimeout(() => {
                    history.replace('/admin')
                }, 1000);

            }).catch((secondRegisterError) => {
                setLoadingOpen(false)
                log_error('SignInErrorTwo', secondRegisterError)
                handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                userCredentials.user.delete()
            })
        }).catch((registerError) => {
            setLoadingOpen(false)
            const errorCode = registerError.code
            const errorMessage = registerError.message
            switch (errorCode) {
                case 'auth/email-already-in-use':
                    setEmailError(true)
                    handleBanner(localized_strings[0].used_email, 'error', 5000)
                    break;
                default:
                    handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                    log_error('SignInError', errorMessage)
                    break;
            }
        })

    }

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible}/>

        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].full_name + ':'} textAlign="start" />
        <Input id={'nameInput'} onError={nameError}/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].email_address + ':'} textAlign="start" />
        <Input id={'emailInput'} type="email" onError={emailError} autoCapitalize="none"/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].password + ':'} textAlign="start" />
        <Input id={'passwordInput'} type="password" onError={passwordError} autoCapitalize="none"/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].repeat_password + ':'} textAlign="start" />
        <Input id={'secondPasswordInput'} type="password" onError={secondPasswordError} autoCapitalize="none"/>
        <Separator size={'SMALL'}/>
        
        <Label title={localized_strings[0].country + ':'} textAlign="start" />
        <Input id={'countryInput'} onError={countryError}/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].city + ':'} textAlign="start" />
        <Input id={'cityInput'} onError={cityError}/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].company_name + ':'} textAlign="start" />
        <Input id={'companyNameInput'} onError={comapnyNameError}/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].company_role + ':'} textAlign="start" />
        <Input id={'companyRoleInput'} onError={companyRoleError}/>

        <p style={{ lineHeight: '2', marginTop: 20, textAlign: 'start' }} >
            {localized_strings[0].signing_accept} <Link to='/privacy' target='_blank'>{localized_strings[0].privacy_and_security}</Link>
        </p>

        <Separator size={'SMALL'}/>

        <OrangeButton title={localized_strings[0].sign_up} onClick={register} />

        <Separator size={'SMALL'}/>

        <LoadingModal open={isLoadingOpen} />   
    </>
}

export default EmployerRegister