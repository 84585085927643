import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../ReusableComponents/Footer";
import LogoFull from "../ReusableComponents/LogoFull";
import NavbarOffers from "../ReusableComponents/NavbarComponents/NavbarOffers";
import ChooserContainer from "./Components/ChooserContainer";
import EmployeeRegister from "./Components/EmployeeRegister";
import EmployerRegister from "./Components/EmployerRegister";

const Register = () => {

    const history = useHistory()

    const [userType, setUserType] = useState('employee')

    const handleRegisterView = () => {
        if (userType==='employee') {
            return <EmployeeRegister />
        }
        return <EmployerRegister />
    }

    const handleHome = () => {
        history.replace('/home')
    }

    return<>
        <div className="container-fluid backgroundImageOpaque"  style={{ position: 'fixed', bottom:0, top:0, left:0, right:0 }}>
            <NavbarOffers handleHome={handleHome}/>
                <div style={{ width: 200, height: 200, marginTop: -80}} >
                    <LogoFull/>
                </div>
                <div className="text-center round_corner_container_border_gray" style={{ position: 'fixed', bottom:90, top:90, left:250, right:250, padding: 20, overflowY: 'auto' }}>
                    <div className="row">
                        <div className="col text-center">
                            <ChooserContainer userType={userType} changeUser={setUserType}/>
                        </div>
                    </div> 
                    {handleRegisterView()}
                </div>
            <Footer/>
        </div>
    </>
}

class RegisterView extends React.Component {
    render() {
        return <Register/>
    }
}

export default RegisterView