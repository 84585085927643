import React, { useState } from "react";
import { get_localized_string } from "../../HelperFunctions";
import Body from "../../ReusableComponents/Body";

const Chooser = ({isChecked, title, onClick}) => {

    const localized_strings = useState(get_localized_string())

    const handleClick = () => {
        if (title !== localized_strings[0].employee) {
            onClick('employer')
        } else {
            onClick('employee')
        }
    }

    return<>
        <div className="row">
            <div className="col text-center">
                <input type='checkbox' checked={isChecked} onClick={handleClick}/>
            </div>
        </div>
        <div className="row">
            <div className="col text-center">
                <Body text={title} />
            </div>
        </div>
    </>
}

export default Chooser