import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

// Reusable components
import Navbar from "../ReusableComponents/navbar"

// Helpers
import UserInteraction from "./components/UserInteraction"
import ShowCard from "./components/ShowCard"
import Footer from "../ReusableComponents/Footer"
import ModalComponent from "../ReusableComponents/ModalComponent"
import ModalDownload from "../ReusableComponents/ModalDownload"

const Home = () => {

    const history = useHistory()

    const [download, setDownload] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setDownload(true);
        }, 500);
    }, [])

    return<>
        <div className='container-fluid backgroundImage' >
            <Navbar history={history} userLoggedIn={false} />
            <div style={{ position: 'fixed', top: 50, bottom: 50, left: 0, right: 0, display: 'flex', overflowY: 'auto' }} >
                <div className="container my-auto">
                    <div className="row" >
                        <div className="col-8" >
                            <ShowCard/>
                        </div>
                        <div className="col" >
                            <UserInteraction />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        <ModalComponent visibility={download} view={<ModalDownload onClose={() => {setDownload(false)}} />}/>
    </>
}

class HomeView extends React.Component {
    render() {
        return <Home/>
    }
}

export default HomeView