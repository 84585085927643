import React, { useEffect, useState } from "react";
import { get_localized_string } from "../../HelperFunctions";
import { colors } from "../../ReusableComponents/Colors";
import Header from "../../ReusableComponents/Header";

const HowWorks = () => {

    const localized_strings = useState(get_localized_string())

    const setBody = () => {
        document.getElementById('body').innerHTML = localized_strings[0].how_it_works_body
    }

    useEffect(() => {setBody()}, [])
    return<>
        <div className="my-auto">
            <Header title={localized_strings[0].how_it_works_title} fontSize={'MEDIUM'} textAlignment={'start'} textColor={colors.blue} padding={'SMALL'}/>

            <p id="body" style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 20 }} ></p>
        </div>
    </>
}

export default HowWorks