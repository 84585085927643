import { doc, updateDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadString } from "firebase/storage";
import React, { useState } from "react";
import firebaseVariables from "../../firebase.config";
import { get_localized_string, LoadingModal, log_error } from "../../HelperFunctions";
import Banner from "../../ReusableComponents/Banner";
import { colors } from "../../ReusableComponents/Colors";
import ImageView from "../../ReusableComponents/ImageView";
import Input from "../../ReusableComponents/Input";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import OrangeButton from "../../ReusableComponents/OrangeButton";
import Separator from "../../ReusableComponents/Separator";

const EditPhotoModal = ({onClose = () => {}, userInfo}) => {

    const localized_strings = useState(get_localized_string())

    const [isLoadingOpen, setLoadingOpen] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const [imageURL, setImageURL] = useState(userInfo.profilePicURL)
    const handleChange = () => {
        
        const file = document.getElementById('imageInput').files[0]

        if(file == null) {
            handleBanner(localized_strings[0].image_empty_error, 'error', 3000)
            return
        }

        setLoadingOpen(true)

        const storageRef = ref(firebaseVariables.firebaseStorage, firebaseVariables.firebaseAuth.currentUser.uid.toString())
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

            const photoString = reader.result

            uploadString(storageRef, photoString, 'data_url').then((uploadResult) => {
                const imageRef = uploadResult.ref
                getDownloadURL(imageRef).then((urlResult) => {
                    const url = urlResult.toString()

                    const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), firebaseVariables.firebaseAuth.currentUser.uid.toString())
                    updateDoc(db_reference, {
                        profilePicURL: url
                    }).then(() => {

                        setLoadingOpen(false)
                        handleBanner(localized_strings[0].image_update_success, 'success', 3000)
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);


                    }).catch((firestoreError) => {
                        log_error(firestoreError.code, firestoreError.message)
                        deleteObject(imageRef)
                        setLoadingOpen(false)
                        handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                    })
                }).catch((urlError) => {
                    log_error(urlError.code, urlError.message)
                    deleteObject(imageRef)
                    setLoadingOpen(false)
                    handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                })
            }).catch((error) => {
                log_error(error.code, error.message)
                setLoadingOpen(false)
                handleBanner(localized_strings[0].something_wrong, 'error', 3000)
            })


        };
        reader.onerror = function (error) {
            setLoadingOpen(false)
            log_error('Base64 Error', error)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
            return
        };
    }

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible} />
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
            <ModalHeader title={localized_strings[0].edit_profile_photo} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start', paddingLeft: 20, paddingRight: 20}}>
            <Separator size='SMALL' />

            <div style={{ width: '100%' }} className="text-center" >
                <ImageView imageURL={imageURL} cornerRadius={10} size="BIG"/>
            </div>

            <Input type="file" id='imageInput' onChange={(input) => {
                                            const file = input.target.files[0]
                                            const reader = new FileReader()
                                            reader.onload = function(event) {
                                                setImageURL(event.target.result)
                                            };
                                            reader.readAsDataURL(file);
                                        }} />
            <Separator size='SMALL'/>

            <OrangeButton title={localized_strings[0].save} onClick={handleChange} />

            <Separator size='SMALL' />

        </div>
        <LoadingModal open={isLoadingOpen}/>
    </>
}

export default EditPhotoModal