import firebaseVariables from './firebase.config'
import { logEvent } from 'firebase/analytics'
import { CircularProgress, Modal } from '@material-ui/core'
import localized_strings from './LocalizedStrings'

export function log_error(errorName, errorDescription) {
    logEvent(firebaseVariables.analyticsApp, 'error_event', {
        event_name: errorName,
        event_description: errorDescription
    })
}

export function check_email(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function check_password(password) {
    let isValid = true

    if(password.length < 8 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        isValid = false
    }

    return isValid
}

export const LoadingModal = ({ open }) => (
    <Modal
    open={open}>
        <div className='modal-layout' >
            <CircularProgress color='inherit' style={{ color:'#FFAD42' }} />
        </div>
    </Modal>
)

function get_language() {
    const lang = navigator.language
    return lang
}

export function get_localized_string() {
    const lang = get_language()
    var lang_dict = {}
    switch (lang) {
        case "es-ES":
            lang_dict = localized_strings.spanish
            break;
        case "en-EN":
            lang_dict = localized_strings.english
            break;
        case "it-IT":
            lang_dict = localized_strings.italian
            break;
        default:
            lang_dict = localized_strings.english
            break;
    }
    return lang_dict
}

export function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 100);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();
    var time = date + ' - ' + month + ' - ' + year;
    return time;
}

export class User {
    active = true;
    city = "";
    companyName = "";
    companyRole = "";
    country = "";
    currency = "€";
    email = "";
    job = "";
    name = "";
    price = "";
    profilePicURL = "";
    timestamp = "";
    userID = "";
    userType = "";
    workExperience = "";
    telephone = "";

    
    constructor(active, city, companyName, companyRole, country, currency, email, job, name, price, profilePicURL, timestamp, userID, userType, workExperience, telephone) {
        this.active = active;
        this.city = city;
        this.companyName = companyName;
        this.companyRole = companyRole;
        this.country = country;
        this.currency = currency;
        this.email = email;
        this.job = job;
        this.name = name;
        this.price = price;
        this.profilePicURL = profilePicURL;
        this.timestamp = timestamp;
        this.userID = userID;
        this.userType = userType;
        this.workExperience = workExperience;
        this.telephone = telephone
    }

    getUser() {
        return {
            active: this.active,
            city: this.city,
            companyName: this.companyName,
            companyRole: this.companyRole,
            country: this.country,
            currency: this.currency,
            email: this.email,
            job: this.job,
            name: this.name,
            price: this.price,
            profilePicURL: this.profilePicURL,
            timestamp: this.timestamp,
            userID: this.userID,
            userType: this.userType,
            workExperience: this.workExperience,
            telephone: this.telephone
        }
    }

}