import React from "react";

import simpleLogo from '../../assests/logo_full_trans.png'

const LogoFull = ({size}) => {

    const handleSize = () => {
        const s = size
        switch (s) {
            case 'SMALL':
                return '30%';
            case 'MEDIUM':
                return '60%';
            case 'BIG':
                return '80%';
            default:
                return '100%';
        }
    }
    return<>
        <img alt='logo' src={simpleLogo} style={{ width: handleSize() }} />
    </>
}

export default LogoFull