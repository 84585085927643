import React from "react";
import CloseButton from "./CloseButton";
import Header from "./Header";

const ModalHeader = ({title, onClose = () => {}}) => {
    return<>
        <div className="container-fluid">
            <div className="row">
                <div className="col my-auto">
                    <Header title={title} fontSize={'MEDIUM'} textAlignment='start' textColor={'white'} />
                </div>
                <div className="col text-end my-auto" >
                    <CloseButton onClose={onClose}/>
                </div>
            </div>
        </div>
    </>
}

export default ModalHeader