import React from "react";

const OrangeButton = ({title, size = '100%', onClick = () =>{}}) => {

    return<>
        <button type='button' className='btn btn-orange' style={{ width: size, fontWeight: 'bold' }} onClick={onClick} >
            {title}
        </button>
    </>
}

export default OrangeButton