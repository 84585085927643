import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { check_email, check_password, get_localized_string, LoadingModal, log_error, User } from "../../HelperFunctions";
import CustomAutocomplete from "../../ReusableComponents/CustomAutocomplete";
import Input from "../../ReusableComponents/Input";
import Label from "../../ReusableComponents/Label";
import OrangeButton from "../../ReusableComponents/OrangeButton";
import Separator from "../../ReusableComponents/Separator";

import firebaseVariables from "../../firebase.config";
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { setDoc, doc, updateDoc, getDoc } from 'firebase/firestore'
import Banner from "../../ReusableComponents/Banner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EmployeeRegister = () => {    

    const history = useHistory()
    
    const localized_strings = useState(get_localized_string())
    const [isLoadingOpen, setLoadingOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [secondPasswordError, setSecondPasswordError] = useState(false)
    const [countryError, setCountryError] = useState(false)
    const [cityError, setCityError] = useState(false)

    const [jobsList, setJobsList] = useState([])

    const getJobList = () => {
        setLoadingOpen(true)
        const ref = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath, 'db')
        getDoc(ref).then((doc) => {
            const data = doc.data()
            setJobsList(data.jobs)
            setLoadingOpen(false)
        }).catch((error) => {
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
            log_error('RetrieveJobRegister', error)
        })
    }

    const register = () => {
        setNameError(false)
        setEmailError(false)
        setPasswordError(false)
        setSecondPasswordError(false)
        setCountryError(false)
        setCityError(false)

        const username = document.getElementById('nameInput').value.toString().trim()
        const email = document.getElementById('emailInput').value.toString().trim()
        const password = document.getElementById('passwordInput').value.toString().trim()
        const repPassword = document.getElementById('secondPasswordInput').value.toString().trim()
        const country = document.getElementById('countryInput').value.toString().trim()
        const city = document.getElementById('cityInput').value.toString().trim()
        const job = document.getElementById('autocompleteInput').value.toString().trim()

        if(username === '') {
            setNameError(true)
            handleBanner(localized_strings[0].name_error, 'error', 2000)
            return
        }

        if (email === '' || !check_email(email)) {
            setEmailError(true)
            handleBanner(localized_strings[0].email_error, 'error', 2000)
            return
        }

        if (!check_password(password)) {
            setPasswordError(true)
            handleBanner(localized_strings[0].password_not_valid, 'error', 5000)
            return
        }

        if (repPassword !== password) {
            setPasswordError(true)
            setSecondPasswordError(true)
            handleBanner(localized_strings[0].password_different, 'error', 3000)
            return
        }
        
        if(country === '') {
            setCountryError(true)
            handleBanner(localized_strings[0].country_empty_error, 'error', 2000)
            return
        }
        if(city === '') {
            setCityError(true)
            handleBanner(localized_strings[0].city_empty_error, 'error', 2000)
            return
        }

        if (job === '') {
            handleBanner(localized_strings[0].job_error, 'error', 3000)
            return
        }

        setLoadingOpen(true)

        createUserWithEmailAndPassword(firebaseVariables.firebaseAuth, email, password).then((userCredentials) => {

            const user = new User(true, city, '', '', country, '€', email, job, username, '', '', + new Date()/100, userCredentials.user.uid.toString(), 'employee', '', '').getUser()

            const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), user.userID);
            setDoc(db_reference, user).then(() => {

                if (!jobsList.includes(job)) {
                    const job_ref = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'))
                    let temList = jobsList
                    temList.push(job)
                    setJobsList(temList)
                    updateDoc(job_ref, {jobs: jobsList})
                }

                setLoadingOpen(false)
                handleBanner(localized_strings[0].welcome_message, 'success', 2000)
                setTimeout(() => {
                    history.replace('/admin')
                }, 1000);

            }).catch((secondRegisterError) => {
                setLoadingOpen(false)
                log_error('SignInErrorTwo', secondRegisterError)
                handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                userCredentials.user.delete()
            })
        }).catch((registerError) => {
            setLoadingOpen(false)
            const errorCode = registerError.code
            const errorMessage = registerError.message
            switch (errorCode) {
                case 'auth/email-already-in-use':
                    setEmailError(true)
                    handleBanner(localized_strings[0].used_email, 'error', 5000)
                    break;
                default:
                    handleBanner(localized_strings[0].something_wrong, 'error', 3000)
                    log_error('SignInError', errorMessage)
                    break;
            }
        })

    }

    useEffect(() => {
        getJobList()
    }, [])

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible}/>

        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].full_name + ':'} textAlign="start" />
        <Input id={'nameInput'} onError={nameError}/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].email_address + ':'} textAlign="start" />
        <Input id={'emailInput'} type="email" onError={emailError} autoCapitalize="none"/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].password + ':'} textAlign="start" />
        <Input id={'passwordInput'} type="password" onError={passwordError} autoCapitalize="none"/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].repeat_password + ':'} textAlign="start" />
        <Input id={'secondPasswordInput'} type="password" onError={secondPasswordError} autoCapitalize="none"/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].country + ':'} textAlign="start" />
        <Input id={'countryInput'} onError={countryError}/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].city + ':'} textAlign="start" />
        <Input id={'cityInput'} onError={cityError}/>
        <Separator size={'SMALL'}/>

        <Label title={localized_strings[0].choose_a_job + ':'} textAlign="start" />
        <CustomAutocomplete list={jobsList} id={'autocompleteInput'} placeholder={localized_strings[0].programmer} />
        <Separator size={'SMALL'}/>

        <p style={{ lineHeight: '2', marginTop: 20, textAlign: 'start' }} >
            {localized_strings[0].signing_accept} <Link to='/privacy' target='_blank'>{localized_strings[0].privacy_and_security}</Link>
        </p>
        <Separator size={'SMALL'}/>

        <OrangeButton title={localized_strings[0].sign_up} onClick={register} />

        <Separator size={'SMALL'}/>

        <LoadingModal open={isLoadingOpen} />
    </>
}

export default EmployeeRegister