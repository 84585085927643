import React, { useState } from "react";
import { get_localized_string } from "../../HelperFunctions";
import { colors } from "../../ReusableComponents/Colors";
import Separator from "../../ReusableComponents/Separator";
import AndroidButton from "./AndroidButton";
import AppleButton from "./AppleButton";
import ImageView from "../../ReusableComponents/ImageView"
import androidqr from '../../../assests/androidqr.png'
import iosqr from '../../../assests/iosqr.png'

const DownloadApp = () => {

    const localized_strings = useState(get_localized_string())

    return<>
    <div style={{ display: 'flex', alignItems: 'center'}}>
        <div style={{ display: "flex", flexDirection: 'column'}}>
            <h4 style={{ textAlign: 'center', zIndex: 2}}>{localized_strings[0].download_app_body}</h4>
            <Separator size='SMALL' />
            <div style={{ marginLeft: 10, marginRight: 10, display: 'flex', flexDirection: 'row', zIndex: 2}}>
                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <h3 style={{ color: colors.green, fontWeight: 'bold'}}>Android</h3>
                    <ImageView imageURL={androidqr}/>
                    <AndroidButton/>
                </div>
                <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <h3 style={{ color: colors.darkGray, fontWeight: 'bold'}}>iOS</h3>
                    <ImageView imageURL={iosqr}/>
                    <AppleButton/>
                </div>
            </div>
        </div>
    </div>
    </>
}

export default DownloadApp