import React from 'react';
import Video from '../Home/components/Video';

const TutorialComponent = () => {
    return <>
        <div className="container-fluid  backgroundImageOpaque" style={{ position: 'fixed', bottom:0, top:0, left:0, right:0 }} >
            <div style={{ position: 'fixed', top: 50, bottom: 50, left: 0, right: 0, overflowY: 'auto' }} >
                <div className="container">
                    <div className="row" >
                        <div className="col" >
                            <h1>Tutorial</h1>
                            <h3>Employee profile creation</h3>
                            <div style={{ height: 500, width: 800, marginBottom: 40}}>
                                <Video url="Ce04U7j7yCw"/>
                            </div>
                            <h3>Employer profile creation</h3>
                            <div style={{ height: 500, width: 800, marginBottom: 40}}>
                                <Video url="mxbNbdZpzXE"/>
                            </div>
                            <h3>Create job offer</h3>
                            <div style={{ height: 500, width: 800, marginBottom: 40}}>
                                <Video url="RGCgF_Nn6as"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

class TutorialScreen extends React.Component {

    render() {
        return <TutorialComponent/>
    }
}

export default TutorialScreen;