import React, { useState } from "react";
import { get_localized_string } from "../../HelperFunctions";
import Chooser from "./Chooser";

const ChooserContainer = ({userType, changeUser}) => {

    const localized_strings = useState(get_localized_string())

    return<>
        <div className="row">
            <div className="col text-center">
                <Chooser isChecked={userType==='employee'?true:false} title={localized_strings[0].employee} onClick={changeUser}/>
            </div>
            <div className="col text-center">
                <Chooser isChecked={userType==='employee'?false:true} title={localized_strings[0].employer} onClick={changeUser}/>
            </div>
        </div>
    </>
}

export default ChooserContainer