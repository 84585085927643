import React from "react";

import simpleLogo from '../../assests/logo_trans.png'

const Logo = ({size}) => {

    const handleSize = () => {
        const s = size
        switch (s) {
            case 'SMALL':
                return '15%';
            case 'MEDIUM':
                return '30%';
            case 'BIG':
                return '45%';
            default:
                return '50%';
        }
    }
    const handleMarginRight = () => {
        const s = size
        switch (s) {
            case 'SMALL':
                return 10;
            case 'MEDIUM':
                return 40;
            case 'BIG':
                return 60;
            default:
                return 0;
        }
    }
    return<>
        <img alt='logo' src={simpleLogo} style={{ width: handleSize(), marginRight: handleMarginRight() }} />
    </>
}

export default Logo