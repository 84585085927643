import { Modal } from "@material-ui/core";
import React from "react";

const ModalComponent = ({visibility, view}) => {
    return<>
    <Modal
    open={visibility} >
        <div className='modal-layout' >
            <div className='modal-container' style={{ width: '60%' }} >
                {view}
            </div>
        </div>
    </Modal>
    </>
}

export default ModalComponent