import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import firebaseVariables from "../../firebase.config";
import { get_localized_string, LoadingModal, log_error } from "../../HelperFunctions";
import Banner from "../../ReusableComponents/Banner";
import { colors } from "../../ReusableComponents/Colors";
import Label from "../../ReusableComponents/Label";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import OrangeButton from "../../ReusableComponents/OrangeButton";
import Separator from "../../ReusableComponents/Separator";

const EditWorkExperienceModal = ({userInfo, onClose = () => {}}) => {

    const localized_strings = useState(get_localized_string())

    const [isLoadingOpen, setLoadingOpen] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const handleChange = () => {
        const workExperience = document.getElementById('input').value.toString().trim()

        if(workExperience !== '') {
            document.getElementById('input').style.borderColor = '#C8C8C8'

            setLoadingOpen(true)
            const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), firebaseVariables.firebaseAuth.currentUser.uid.toString())
            updateDoc(db_reference, {workExperience: workExperience}).then(() => {
                setLoadingOpen(false)
                handleBanner(localized_strings[0].work_experience_update_success, 'success', 3000)
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }).catch((error) => {
                log_error(error.code, error.message)
                setLoadingOpen(false)
                handleBanner(localized_strings[0].something_wrong, 'error', 3000)
            })
        } else {
            document.getElementById('input').style.borderColor = 'red'
            handleBanner(localized_strings[0].work_experience_update_error, 'error', 3000)
        }
    }

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible} />
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
            <ModalHeader title={localized_strings[0].edit_work_experience} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start', paddingLeft: 20, paddingRight: 20}}>
            <Separator size='SMALL' />
            <Label title={localized_strings[0].work_experience + ':'}/>
            <textarea className="form-control" id='input' style={{height: 200}} defaultValue={userInfo.workExperience===undefined?'':userInfo.workExperience}></textarea>                        
            <Separator size='SMALL' />
            <OrangeButton title={localized_strings[0].save} onClick={handleChange} />
            <Separator size='SMALL' />
        </div>
        <LoadingModal open={isLoadingOpen}/>
    </>
}

export default EditWorkExperienceModal