import React, { useEffect, useState } from "react";
import { check_email, get_localized_string, LoadingModal, log_error } from "../../HelperFunctions";
import { colors } from "../../ReusableComponents/Colors";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import Separator from "../../ReusableComponents/Separator";
import Banner from "../../ReusableComponents/Banner";
import firebaseVariables from "../../firebase.config";
import { doc, deleteDoc, setDoc } from "firebase/firestore";
import Label from "../../ReusableComponents/Label";
import Input from "../../ReusableComponents/Input";
import OrangeButton from "../../ReusableComponents/OrangeButton";

const EditOfferModal = ({onClose = () => {}, offer = []}) => {

    const localized_strings = useState(get_localized_string())

    const [isLoadingOpen, setLoadingOpen] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const [titleError, setTitleError] = useState(false)
    const [companyNameError, setCompanyNameError] = useState(false)
    const [jobError, setJobError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [countryError, setCountryError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [salaryError, setSalaryError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    const populateInput = () => {
        document.getElementById('titleInput').value = offer.title
        document.getElementById('companyNameInput').value = offer.companyName
        document.getElementById('jobInput').value = offer.job
        document.getElementById('emailInput').value = offer.email
        document.getElementById('phoneInput').value = offer.telephone
        document.getElementById('countryInput').value = offer.country
        document.getElementById('cityInput').value = offer.city
        document.getElementById('salaryInput').value = offer.salary
        document.getElementById('descriptionInput').value = offer.description
        document.getElementById('visibilityInput').value = offer.visibility
    }

    const defineAction = () => {
        const title = document.getElementById('titleInput').value.toString().trim()
        const companyName = document.getElementById('companyNameInput').value.toString().trim()
        const job = document.getElementById('jobInput').value.toString().trim()
        const email = document.getElementById('emailInput').value.toString().trim()
        const phone = document.getElementById('phoneInput').value.toString().trim()
        const country = document.getElementById('countryInput').value.toString().trim()
        const city = document.getElementById('cityInput').value.toString().trim()
        const salary = document.getElementById('salaryInput').value.toString().trim()
        const description = document.getElementById('descriptionInput').value.toString().trim()
        const visibility = document.getElementById('visibilityInput').value.toString().trim()

        if (title === offer.title &&
            companyName === offer.companyName &&
            job === offer.job &&
            email === offer.email &&
            phone === offer.telephone &&
            country === offer.country &&
            city === offer.city &&
            salary === offer.salary &&
            description === offer.description &&
            visibility !== offer.visibility) {
                changeVisibility()
                return
        }

        if (title === offer.title &&
            companyName === offer.companyName &&
            job === offer.job &&
            email === offer.email &&
            phone === offer.telephone &&
            country === offer.country &&
            city === offer.city &&
            salary === offer.salary &&
            description === offer.description &&
            visibility === offer.visibility) {
                onClose()
                return
        }

        updateOffer()
    }

    const changeVisibility = () => {
        const visibility = document.getElementById('visibilityInput').value.toString().trim()
        const oldID = offer.id
        const newID = new Date()/100 + '-' + firebaseVariables.firebaseAuth.currentUser.uid.toString()
        const timestamp = new Date()/100

        offer.visibility = visibility
        offer.id = newID
        offer.timestamp = timestamp

        setLoadingOpen(true)

        const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/offers'), newID);
        setDoc(db_reference, offer).then(() => {

            const db_reference_two = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users/' + firebaseVariables.firebaseAuth.currentUser.uid.toString() + '/offers'), newID);
            setDoc(db_reference_two, offer).then(() => {
                setLoadingOpen(false)
                handleBanner(localized_strings[0].offer_updated, 'success', 3000)
                const db_reference_delete_one = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/offers'), oldID);
                const db_reference_delete_two = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users/' + firebaseVariables.firebaseAuth.currentUser.uid.toString() + '/offers'), oldID);
                deleteDoc(db_reference_delete_one)
                deleteDoc(db_reference_delete_two).then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                })
            })

        }).catch((error) => {
            setLoadingOpen(false)
            log_error('NewOffer', error)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    const updateOffer = () => {
        setTitleError(false)
        setCompanyNameError(false)
        setJobError(false)
        setEmailError(false)
        setPhoneError(false)
        setCountryError(false)
        setCityError(false)
        setSalaryError(false)
        setDescriptionError(false)

        const title = document.getElementById('titleInput').value.toString().trim()
        const companyName = document.getElementById('companyNameInput').value.toString().trim()
        const job = document.getElementById('jobInput').value.toString().trim()
        const email = document.getElementById('emailInput').value.toString().trim()
        const phone = document.getElementById('phoneInput').value.toString().trim()
        const country = document.getElementById('countryInput').value.toString().trim()
        const city = document.getElementById('cityInput').value.toString().trim()
        const salary = document.getElementById('salaryInput').value.toString().trim()
        const description = document.getElementById('descriptionInput').value.toString().trim()
        const newID = new Date()/100 + '-' + firebaseVariables.firebaseAuth.currentUser.uid.toString()
        const timestamp = new Date()/100

        const visibility = document.getElementById('visibilityInput').value.toString().trim()

        const oldID = offer.id

        if (title === '') {
            setTitleError(true)
            handleBanner(localized_strings[0].job_title_empty_error, 'error', 3000)
            return
        }
        if (companyName === '') {
            setCompanyNameError(true)
            handleBanner(localized_strings[0].company_name_empty_error, 'error', 3000)
            return
        }
        if (job === '') {
            setJobError(true)
            handleBanner(localized_strings[0].job_name_empty_error, 'error', 3000)
            return
        }
        if (email === '' || !check_email(email)) {
            setEmailError(true)
            handleBanner(localized_strings[0].email_error, 'error', 3000)
            return
        }
        if (phone === '') {
            setPhoneError(true)
            handleBanner(localized_strings[0].telephone_empty_error, 'error', 3000)
            return
        }
        if (country === '') {
            setCountryError(true)
            handleBanner(localized_strings[0].country_empty_error, 'error', 3000)
            return
        }
        if (city === '') {
            setCityError(true)
            handleBanner(localized_strings[0].city_empty_error, 'error', 3000)
            return
        }
        if (salary === '') {
            setSalaryError(true)
            handleBanner(localized_strings[0].salary_empty_error, 'error', 3000)
            return
        }
        if (description === '') {
            setDescriptionError(true)
            handleBanner(localized_strings[0].job_description_empty_error, 'error', 3000)
            return
        }

        const offerInfo = {
            title: title, 
            companyName: companyName,
            job: job, 
            country: country, 
            city: city, 
            description: description,
            id: newID,
            timestamp: timestamp,
            visibility: visibility,
            salary: salary,
            publisherID: firebaseVariables.firebaseAuth.currentUser.uid.toString(),
            email: email, 
            telephone: phone
        }


        setLoadingOpen(true)

        const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/offers'), newID);
        setDoc(db_reference, offerInfo).then(() => {

            const db_reference_two = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users/' + firebaseVariables.firebaseAuth.currentUser.uid.toString() + '/offers'), newID);
            setDoc(db_reference_two, offerInfo).then(() => {
                setLoadingOpen(false)
                handleBanner(localized_strings[0].offer_updated, 'success', 3000)
                const db_reference_delete_one = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/offers'), oldID);
                const db_reference_delete_two = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users/' + firebaseVariables.firebaseAuth.currentUser.uid.toString() + '/offers'), oldID);
                deleteDoc(db_reference_delete_one)
                deleteDoc(db_reference_delete_two).then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                })
            })

        }).catch((error) => {
            setLoadingOpen(false)
            log_error('NewOffer', error)
            console.log(error)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    useEffect(() => {
        populateInput()
    }, [])

    return<>
        <div style={{position: "fixed", top: 45, bottom: 45, left: 200, right: 200, backgroundColor: 'white', borderRadius: 10, overflowY: "auto"}}>
            <Banner message={errorMessage} type={errorType} isVisible={errorVisible} />
            <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
                <ModalHeader title={localized_strings[0].edit_offer} onClose={onClose} />
            </div>
            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start', padding: 20}}>
                <Label title={localized_strings[0].visibility + ':'}/>
                <select className="form-select" id="visibilityInput">
                    <option value="visible">{localized_strings[0].visible}</option>
                    <option value="hidden">{localized_strings[0].hidden}</option>
                </select>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].offer_title + ':'}/>
                <Input id={'titleInput'} onError={titleError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].company_name + ':'}/>
                <Input id={'companyNameInput'} onError={companyNameError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].job + ':'}/>
                <Input id={'jobInput'} onError={jobError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].contact_email + ':'}/>
                <Input id={'emailInput'} onError={emailError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].contact_phone + ':'}/>
                <Input id={'phoneInput'} onError={phoneError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].country + ':'}/>
                <Input id={'countryInput'} onError={countryError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].city + ':'}/>
                <Input id={'cityInput'} onError={cityError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].salary_month + ':'}/>
                <Input id={'salaryInput'} onError={salaryError}/>
                <Separator size={'SMALL'}/>

                <Label title={localized_strings[0].job_descriptio + ':'}/>
                <textarea className="form-control" id='descriptionInput' style={{ height: 250, borderColor: descriptionError?'red':colors.lightGray}}></textarea>  
                <Separator size={'SMALL'}/>

                <OrangeButton title={localized_strings[0].save} size="100%" onClick={defineAction}/>
                <Separator size={'SMALL'}/>

            </div>
        </div>
        <LoadingModal open={isLoadingOpen}/>
    </>
}

export default EditOfferModal