import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import firebaseVariables from "../firebase.config";
import { check_email, get_localized_string, LoadingModal, log_error } from "../HelperFunctions";
import Banner from "../ReusableComponents/Banner";
import { colors } from "../ReusableComponents/Colors";
import Footer from "../ReusableComponents/Footer";
import Input from "../ReusableComponents/Input";
import Label from "../ReusableComponents/Label";
import NavbarOffers from "../ReusableComponents/NavbarComponents/NavbarOffers";
import OrangeButton from "../ReusableComponents/OrangeButton";
import Separator from "../ReusableComponents/Separator";

const NewJobOffer = () => {

    const history = useHistory()
    const localized_strings = useState(get_localized_string())

    const [isLoadingOpen, setLoadingOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const [titleError, setTitleError] = useState(false)
    const [companyNameError, setCompanyNameError] = useState(false)
    const [jobError, setJobError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [countryError, setCountryError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [salaryError, setSalaryError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    const handleHome = () => {
        history.replace('/admin')
    }

    const createOffer = () => {
        setTitleError(false)
        setCompanyNameError(false)
        setJobError(false)
        setEmailError(false)
        setPhoneError(false)
        setCountryError(false)
        setCityError(false)
        setSalaryError(false)
        setDescriptionError(false)

        const title = document.getElementById('titleInput').value.toString().trim()
        const companyName = document.getElementById('companyNameInput').value.toString().trim()
        const job = document.getElementById('jobInput').value.toString().trim()
        const email = document.getElementById('emailInput').value.toString().trim()
        const phone = document.getElementById('phoneInput').value.toString().trim()
        const country = document.getElementById('countryInput').value.toString().trim()
        const city = document.getElementById('cityInput').value.toString().trim()
        const salary = document.getElementById('salaryInput').value.toString().trim()
        const description = document.getElementById('descriptionInput').value.toString().trim()
        const id = new Date()/100 + '-' + firebaseVariables.firebaseAuth.currentUser.uid.toString()
        const timestamp = new Date()/100

        if (title === '') {
            setTitleError(true)
            handleBanner(localized_strings[0].job_title_empty_error, 'error', 3000)
            return
        }
        if (companyName === '') {
            setCompanyNameError(true)
            handleBanner(localized_strings[0].company_name_empty_error, 'error', 3000)
            return
        }
        if (job === '') {
            setJobError(true)
            handleBanner(localized_strings[0].job_name_empty_error, 'error', 3000)
            return
        }
        if (email === '' || !check_email(email)) {
            setEmailError(true)
            handleBanner(localized_strings[0].email_error, 'error', 3000)
            return
        }
        if (phone === '') {
            setPhoneError(true)
            handleBanner(localized_strings[0].telephone_empty_error, 'error', 3000)
            return
        }
        if (country === '') {
            setCountryError(true)
            handleBanner(localized_strings[0].country_empty_error, 'error', 3000)
            return
        }
        if (city === '') {
            setCityError(true)
            handleBanner(localized_strings[0].city_empty_error, 'error', 3000)
            return
        }
        if (salary === '') {
            setSalaryError(true)
            handleBanner(localized_strings[0].salary_empty_error, 'error', 3000)
            return
        }
        if (description === '') {
            setDescriptionError(true)
            handleBanner(localized_strings[0].job_description_empty_error, 'error', 3000)
            return
        }

        const offerInfo = {
            title: title, 
            companyName: companyName,
            job: job, 
            country: country, 
            city: city, 
            description: description,
            id: id,
            timestamp: timestamp,
            visibility: 'visible',
            salary: salary,
            publisherID: firebaseVariables.firebaseAuth.currentUser.uid.toString(),
            email: email, 
            telephone: phone
        }


        setLoadingOpen(true)

        const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/offers'), id);
        setDoc(db_reference, offerInfo).then(() => {

            const db_reference_two = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users/' + firebaseVariables.firebaseAuth.currentUser.uid.toString() + '/offers'), id);
            setDoc(db_reference_two, offerInfo).then(() => {
                setLoadingOpen(false)
                handleBanner(localized_strings[0].offer_succees, 'success', 3000)
                setTimeout(() => {
                    history.replace('/offers')
                }, 2000);
            })

        }).catch((error) => {
            setLoadingOpen(false)
            log_error('NewOffer', error)
            console.log(error)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    return<>

        <div className="container-fluid backgroundImageOpaque" style={{ position: 'fixed', bottom:0, top:0, left:0, right:0 }} >
            <NavbarOffers handleHome={handleHome}/>
            
            <div className="text-center round_corner_container_border_gray" style={{ overflowY: 'auto', position: 'fixed', bottom: 90, top: 90, right: 400, left: 400 }} >
                <div style={{ padding: 20}} >
                    <Banner message={errorMessage} type={errorType} isVisible={errorVisible}/>
                    <Label title={localized_strings[0].offer_title + ':'}/>
                    <Input id={'titleInput'} onError={titleError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].company_name + ':'}/>
                    <Input id={'companyNameInput'} onError={companyNameError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].job + ':'}/>
                    <Input id={'jobInput'} onError={jobError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].contact_email + ':'}/>
                    <Input id={'emailInput'} onError={emailError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].contact_phone + ':'}/>
                    <Input id={'phoneInput'} onError={phoneError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].country + ':'}/>
                    <Input id={'countryInput'} onError={countryError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].city + ':'}/>
                    <Input id={'cityInput'} onError={cityError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].salary_month + ':'}/>
                    <Input id={'salaryInput'} onError={salaryError}/>
                    <Separator size={'SMALL'}/>

                    <Label title={localized_strings[0].job_descriptio + ':'}/>
                    <textarea className="form-control" id='descriptionInput' style={{height: 250, borderColor: descriptionError?'red':colors.lightGray}}></textarea>  
                    <Separator size={'SMALL'}/>

                    <OrangeButton title={localized_strings[0].save} size="100%" onClick={createOffer}/>
                    <Separator size={'SMALL'}/>

                </div>
            </div>

            <Footer/>
        </div>
        <LoadingModal open={isLoadingOpen}/>
    </>
}

class NewJobOfferView extends React.Component {
    render() {
        return <NewJobOffer/>
    }
}

export default NewJobOfferView