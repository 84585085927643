import React, { useState } from "react";
import { get_localized_string, LoadingModal, log_error } from "../../HelperFunctions";
import { colors } from "../../ReusableComponents/Colors";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import ControlButtons from "./ControlButtons";
import Separator from "../../ReusableComponents/Separator";
import Banner from "../../ReusableComponents/Banner";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail, signOut } from "firebase/auth";
import firebaseVariables from "../../firebase.config";
import { doc, updateDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";

const SettingsModal = ({onClose = () => {}, openCE = () => {}}) => {

    const history = useHistory();

    const localized_strings = useState(get_localized_string())

    const [isLoadingOpen, setLoadingOpen] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const signout = () => {
        signOut(firebaseVariables.firebaseAuth).catch((error) => {
            log_error(error.code, error.message)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    const handleChangePassword = () => {
        setLoadingOpen(true)
        sendPasswordResetEmail(firebaseVariables.firebaseAuth, firebaseVariables.firebaseAuth.currentUser.email).then(() => {
            setLoadingOpen(false)
            handleBanner(localized_strings[0].password_reset_sent, 'success', 5000)
        }).catch((error) => {
            log_error(error.code, error.message)
            setLoadingOpen(false)
            handleBanner(localized_strings[0].something_wrong, 'error', 5000)
        })
    }

    const handleDeactivateAccount = () => {
        setLoadingOpen(true)
        const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), firebaseVariables.firebaseAuth.currentUser.uid.toString())
        updateDoc(db_reference, {
            active: false
        }).then(() => {
            setLoadingOpen(false)
            handleBanner(localized_strings[0].account_deactivation_message, 'warning', 3000)
            setTimeout(() => {
                signout()
            }, 3000);
        }).catch((error) => {
            log_error(error.code, error.message)
            setLoadingOpen(false)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    const handleChangeEmail = () => {
        openCE()
        onClose()
    }

    const handleTutorial = () => {
        history.push('/tutorial');
    }

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible} />
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
            <ModalHeader title={localized_strings[0].settings} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start'}}>
            <Separator size='SMALL' />
            <Link className='btn profile-transparent-button' to='/privacy' target='_blank' >
                {localized_strings[0].privacy_and_security}
            </Link>
            <ControlButtons title={localized_strings[0].change_email} onClick={handleChangeEmail} />
            <ControlButtons title={localized_strings[0].change_password} onClick={handleChangePassword} />
            <ControlButtons title={localized_strings[0].tutorial} onClick={handleTutorial} />
            <ControlButtons title={localized_strings[0].sign_out} onClick={signout} color="red" />
            <ControlButtons title={localized_strings[0].deactivate_account} onClick={handleDeactivateAccount} color="red" />
            <Separator size='SMALL' />
        </div>
        <LoadingModal open={isLoadingOpen}/>
    </>
}

export default SettingsModal