import React from "react";

const CloseButton = ({ onClose = ()=>{} }) => {
    return<>
        <button type='button' className='btn modal-close-button my-auto' onClick={onClose} >
            <i className="fas fa-times"></i>
        </button>
    </>
}

export default CloseButton