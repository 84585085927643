import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import firebaseVariables from "../firebase.config";
import { get_localized_string, LoadingModal, log_error, timeConverter } from "../HelperFunctions";
import Banner from "../ReusableComponents/Banner";
import BlueButton from "../ReusableComponents/BlueButton";
import { colors } from "../ReusableComponents/Colors";
import Header from "../ReusableComponents/Header";
import Input from "../ReusableComponents/Input";
import Label from "../ReusableComponents/Label";
import LineSeparator from "../ReusableComponents/LineSeparator";
import ModalComponent from "../ReusableComponents/ModalComponent";
import NavbarOffers from "../ReusableComponents/NavbarComponents/NavbarOffers";
import PlusButton from "../ReusableComponents/PlusButton";
import Separator from "../ReusableComponents/Separator";
import ContactModal from "../Search/component/ContactModal";

const JobOfferOn = () => {

    const history = useHistory()
    const localized_strings = useState(get_localized_string())

    const handleHome = () => {
        history.replace('/admin')
    }

    const [contactModalVisible, setContactModalVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState()

    const [isLoadingOpen, setLoadingOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const [employee, setEmployee] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const getCountries = () => {
        setLoadingOpen(true)
        let countries = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'offers')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()
                if(!countries.includes(data.country)) {
                    countries.push(data.country)
                }
            })

            let html = '<option value="Country">' + localized_strings[0].country + ' ...</option>'
            countries.forEach((c) => {

                html += '<option value="' + c + '">' + c + '</option>'
                
            })

            document.getElementById('countryInput').innerHTML = html
            setLoadingOpen(false)
        })
    }

    const onCountryChange = () => {
        const country = document.getElementById('countryInput').value.toString().trim()
        if(country!=='Country') {
            getCities(country)
        } else {
            document.getElementById('cityInput').disabled = true
        }
    
    }

    const getCities = (count) => {
        setLoadingOpen(true)
        let cities = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'offers')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()
                if (data.country === count) {
                    if(!cities.includes(data.city)) {
                        cities.push(data.city)
                    }
                }
            })


            let html = '<option value="City">' + localized_strings[0].city + ' ...</option>'
            cities.forEach((c) => {

                html += '<option value="' + c + '">' + c + '</option>'
                
            })

            document.getElementById('cityInput').innerHTML = html
            document.getElementById('cityInput').disabled = false
            setLoadingOpen(false)

        })
    }

    const defineSearch = () => {
        const country = document.getElementById('countryInput').value.toString().trim()
        const city = document.getElementById('cityInput').value.toString().trim()
        const cityDisabled = document.getElementById('cityInput').disabled

        if (country !== 'Country' &&
                !cityDisabled &&
                city === 'City') {
            getOffersByCountry(country)
            return
        }

        if (country !== 'Country' &&
                !cityDisabled &&
                city !== 'City') {
            getOffersByCountryCity(country, city)
            return
        }

        searchByNone()
    }

    const searchByNone = () => {
        setLoadingOpen(true)
        let offers = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'offers')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()
                if (data.visibility !== 'hidden') {
                    offers.push(data)
                }
            })
    
            populateOffersList(offers)
        })
    }

    const getOffersByCountry = (country) => {
        setLoadingOpen(true)
        let offers = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'offers')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()

                if (data.visibility !== 'hidden' &&
                    (data.country !== undefined || data.country !== '') && 
                    data.country.toUpperCase() === country.toUpperCase()) {
                        offers.push(data)
                }
            })
    
            populateOffersList(offers)
        })
    }

    const getOffersByCountryCity = (country, city) => {
        setLoadingOpen(true)
        let offers = []

        const db_reference = collection(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db'), 'offers')
        const q = query(db_reference)
        getDocs(q).then((querySnapshot) => {

            querySnapshot.forEach( snap => {
                const data = snap.data()

                if (data.visibility !== 'hidden' &&
                    (data.country !== undefined || data.country !== '') && 
                    data.country.toUpperCase() === country.toUpperCase() &&
                    data.city !== undefined && 
                    data.city.toUpperCase() === city.toUpperCase()) {
                        offers.push(data)
                }
            })
    
            populateOffersList(offers)
        })
    }

    const populateOffersList = (list) => {
        setLoadingOpen(false)
        if (list.length == 0) {
            handleBanner(localized_strings[0].no_job_offer_match, 'warning', 3000)
            document.getElementById('offersBody').innerHTML = ''
            return
        }
        list.reverse()
        let html = ''
        list.forEach(offer => {
            html += '<li class="list-group-item">' +
            '<h5 style="color: #FFAD42;">' + offer.title + '</h5>' +
            '<p style="font-size: 12px;">'+ localized_strings[0].published_on + ': ' + timeConverter(offer.timestamp) + '</p>' +
            '<p>' + localized_strings[0].publisher + ': <span style="color: #4588F5; font-weight: 900;">' + offer.companyName + '</span></p>' +
            '<p>' + localized_strings[0].job + ': <span style="color: #4588F5; font-weight: 900;">' + offer.job + '</span></p>' +
            '<p>' + localized_strings[0].location + ': <span style="color: #4588F5; font-weight: 900;">' + offer.city + ', ' + offer.country + '.</span></p>' +
            '<p>' + localized_strings[0].salary_month + ': <span style="color: #4588F5; font-weight: 900;">' + offer.salary + '</span></p>' +
            '<h6 style="color: #FFAD42;">' + localized_strings[0].job_descriptio + '</h6>' + 
            '<div class="blue-divider" ></div>' +
            '<p style="white-space: pre-line;">' + offer.description + '</p>' +
            '<div class="text-end" >' +
            '<button type="button" class="btn btn-orange" style="padding-left: 40px; padding-right: 40px;" id="' + offer.id + '">' + localized_strings[0].contact +'</button>' +
            '</div>' +
            '</li>'
        })

        document.getElementById('offersBody').innerHTML = html

        list.forEach(offer => {
            document.getElementById(offer.id).addEventListener('click',  () => {
                const su = {
                    name: offer.companyName,
                    email: offer.email,
                    telephone: offer.telephone
                }
                setSelectedUser(su)
                setContactModalVisible(true)
            })
        })
    }

    const handleNewOffer = () => {
        history.push('/new-offer')
    }

    const isEmployer = () => {
        const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), firebaseVariables.firebaseAuth.currentUser.uid.toString())
        getDoc(db_reference).then((user) => {
            if(user.exists()) {
                const data = user.data()
                if(data.userType === 'employee') {
                    setEmployee(true)
                }
            }
        }).catch((error) => {
            log_error(error.code, error.message)
            setEmployee(true)
        })
    }

    useEffect(() => {
        isEmployer()
        getCountries()
        searchByNone()
    }, [])

    return<>
        <div className="container-fluid backgroundImageOpaque" style={{ position: 'fixed', bottom:0, top:0, left:0, right:0 }} >
            <Banner message={errorMessage} type={errorType} isVisible={errorVisible}/>
            <NavbarOffers handleHome={handleHome}/>
            
            <Separator size={'MEDIUM'}/>

            <div className="row" >

                <div className="col-4">
                    <div className="round_corner_container">
                        <div style={{ backgroundColor: colors.orange, borderTopLeftRadius: 10, borderTopRightRadius: 10}} >
                            <Header title={localized_strings[0].filters} fontSize={'MEDIUM'} textAlignment={'start'} textColor={'white'}/>
                        </div>
                        <Separator size={'SMALL'}/>
                        <div  style={{ paddingLeft:10, paddingRight: 10 }}>

                            <select className="form-select" id="countryInput" onChange={onCountryChange}>
                            </select>
                            <Separator size={'SMALL'}/>


                            <select className="form-select" id="cityInput" disabled>
                                <option value="City">{localized_strings[0].city} ...</option>
                            </select>
                            <Separator size={'SMALL'}/>

                            <BlueButton icon="fas fa-search" onClick={defineSearch}/>
                            <Separator size={'SMALL'}/>

                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="row" >
                        <div className="col">
                            <Header title={localized_strings[0].new_offers} fontSize={'MEDIUM'} textAlignment={'start'} textColor={colors.blue}/>
                        </div>
                        <div className="col-2" style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
                            <BlueButton title={localized_strings[0].create_offer} onClick={handleNewOffer} disabled={employee}/>
                        </div>
                    </div>
                    <LineSeparator />
                    <ul className='list-group' id='offersBody' style={{ overflowY: 'auto', position: 'fixed', bottom: 20, top: 175, right: 10, width: '65%' }}/>
                </div>
            </div>
        </div>
        <LoadingModal open={isLoadingOpen}/>
        <ModalComponent visibility={contactModalVisible} view={<ContactModal userInfo={selectedUser} onClose={()=>{setContactModalVisible(false)}} />}/>
    </>
}

class JobOfferOnView extends React.Component {
    render(){
        return <JobOfferOn/>
    }
}

export default JobOfferOnView