const english = {
    who_we_are_title: "Who we are",
    who_we_are_body: "Hephaestus is an online job portal made for employers to find the right employee for their needs.<br><br>For the <b>employer</b>, Hephaestus provides immediate access to thousands of employees with specific skills, without the need to place job ads.<br><br>For the <b>employee</b>, Hephaestus offers a constant source of part-time to full-time job opportunities, without the trouble and expenses of advertising and self-promotion. You just need to fill your profile and wait for the employers to reach out to you.<br><br>Hephaestus fights to provide a safe and simple place for employers to find employees around the world. Join our increasing family to maximize your earnings.",
    FAQ_title: "FAQ",
    FAQ_body: '<span style="font-size: 20px; font-weight: bold;" >Is it free?</span><br>Yes, but in a future with page improvements we might charge something to the employer to contact employees.<br><br><span style="font-size: 20px; font-weight: bold;" >Is my data safe?</span><br>Yes, your data is completely safe even though we don\'t storage sensitive data. Here you can read our <a target="_blank" href="/privacy" >Privacy & Security policy.</a><br><br><span style="font-size: 20px; font-weight: bold;" >Where does the payment between employer and employee occurs?</span><br>The payment as the discussion of the job between the employer and the employe occurs outside the platform.',
    how_it_works_title: "How it works",
    how_it_works_body: '<span style="font-size: 18px;" >It\'s very easy.</span><br><br>If you are an <b>employee</b> looking for a job just sign up on our platform, go to your profile,fill all your information and wait for an employer to reach out to you.<br><br>If you are an <b>employer</b> looking for an employee, sign up in our plaform, fill your profile data and search for an employee. Remember, if you don\'t see the job you are looking for in our search bar dropdown list it means we still don\'t have any user with that job.',
    name_error: "Write a valid name.",
    email_error: "Write a valid e-mail.",
    password_not_valid: "Password must be at least 8 characters long and include at least one UPPERCASE, one lowercase and one special character.",
    password_different: "Passwords are different.",
    job_error: "Write a valid job.",
    company_name_error: "Write a valid company name.",
    company_role_error: "Write a valid company role.",
    welcome_message: "Welcome to Hephaestus!",
    something_wrong: "Something went wrong, try again later.",
    used_email: "This e-email is already in use.",
    not_active_user: "This user is deactivated, to reactivate this account contact: egel.development@gmail.com",
    not_signed_user: "This user is not signed in the system.",
    wrong_credentials: "Wrong credentials.",
    invalid_credentials: "Invalid credentials",
    login: "Log in",
    email_address: "Email address",
    password: "Password",
    click_here: "Click here",
    full_name: "Full name",
    repeat_password: "Repeat password",
    employee: "Employee",
    employer: "Employer",
    choose_a_job: "Job (choose one from the list or write a new one)",
    company_name: "Company name",
    company_role: "My role in the company",
    signing_accept: "By signing in I accept the",
    privacy_and_security: "Privacy & Security policy.",
    sign_out: "Sign out",
    edit_profile_photo: "Edit profile photo",
    edit_prfile: "Edit profile",
    edit_work_experience: "Edit work experience",
    settings: "Settings",
    general_info: "General info",
    email: "E-mail",
    country: "Country",
    city: "City",
    userID: "UserID",
    price_x_hour: "Price per hour",
    work_experience: "Work experience",
    price: "Price",
    currency: "Currency",
    save: "Save",
    change_email: "Change e-mail",
    change_password: "Change password",
    deactivate_account: "Deactivate account",
    new_email: "New e-mail",
    work_experience_update_success: "Work experience updated successfully.",
    work_experience_update_error: "Work experience can\'t be null.",
    company_name_empty_error: "Company name can\'t be empty.",
    company_role_empty_error: "Your role in the company can\'t be empty.",
    name_empty_error: "Full name can\'t be empty.",
    country_empty_error: "Country can\'t be empty.",
    city_empty_error: "City can\'t be empty.",
    user_info_update_success: "User information updated successfully.",
    image_empty_error: "An image must be selected to continue.",
    image_update_success: "Profile photo updated successfully!",
    change_email_error: "Check your password or new e-mail.",
    email_update_success: "E-mail updated successfully.",
    password_reset_sent: "The instructions to change your password have been sent to your e-mail.",
    account_deactivation_message: "Your account have been deactivated, to reactivate it please contact us on: egel.development@gmail.com",
    contact: "Contact",
    filters: "Filters",
    max_price_x_hour: "Max. price per hour",
    no_worker_params: "There is no worker with the selected parameters.",
    no_params_no_search: "Wrong search parameters.",
    search_employee: "Search employee",
    view_job_offers: "Job offers",
    sign_up: "Sign up",
    still_no_account: 'Still without an account?',
    programmer: 'Programmer',
    same_email: 'E-mails are the same.',
    forgot_password: 'I forgot my password.',
    forgot_pass_email: 'Write your account email.',
    job: 'Job',
    login_first: 'To contact an employee you must login or sign up.',
    home: 'Home',
    download_app: 'Download our APP.',
    download_app_body: 'Download our APP available on Google Play and Apple Store.',
    telephone: 'Telephone',
    telephone_empty_error: 'Telephone number can\'t be empty.',
    do_contact: 'Do you want to contact ',
    contact_email: 'You can contact him via email',
    contact_telephone: 'You can contact him via telephone',
    min_price_month: 'Min. earning per month',
    new_offers: 'New Offers',
    offer_title: 'Offer title',
    job_descriptio: 'Job description',
    offer_succees: 'Offer published successfully!',
    salary_month: 'Monthly salary',
    salary_empty_error: 'Salary can\'t be empty',
    job_title_empty_error: 'Title can\'t be empty.',
    job_name_empty_error: 'Job can\`t be empty.',
    job_description_empty_error: 'Job description can\'t be empty.',
    contact_email: 'Contact email',
    contact_phone: 'Contact telephone',
    published_on: 'Published on',
    publisher: 'Creator',
    location: 'Location',
    no_job_offer_match: 'No matching offer with your search parameters.',
    edit_offer: 'Edit offer',
    visibility: 'Visibility',
    visible: 'Visible',
    hidden: 'Hidden',
    offer_updated: 'Offer updated successfully!',
    create_offer: 'Create offer',
    tutorial: 'Tutorial'
}



const italian = {
    who_we_are_title: "Chi siamo",
    who_we_are_body: "Hephaestus è un portale di lavoro online creato per i datori di lavoro per trovare il dipendente giusto per le loro esigenze.<br><br>Per il <b>datore di lavoro</b>, Hephaestus fornisce accesso immediato a migliaia di dipendenti con competenze specifiche, senza la necessità di inserire un lavoro annunci.<br><br>Per il <b>dipendente</b>, Hephaestus offre una fonte costante di opportunità di lavoro da part-time a full-time, senza i problemi e le spese di pubblicità e autopromozione. Devi solo compilare il tuo profilo e attendere che i datori di lavoro ti contattino.<br><br>Efesto si batte per fornire ai datori di lavoro un luogo sicuro e semplice per trovare dipendenti in tutto il mondo. Unisciti alla nostra famiglia in crescita per massimizzare i tuoi guadagni.",
    FAQ_title: "FAQ",
    FAQ_body: '<span style="font-size: 20px; font-weight: bold;" >È gratuito?</span><br>Sì, ma in futuro con miglioramenti della pagina potremmo addebitare qualcosa al datore di lavoro per contattare i dipendenti.<br><br><span style="font-size: 20px; font-weight: bold;" >I miei dati sono al sicuro?</span><br>Sì, i tuoi dati sono completamente al sicuro anche se non memorizziamo dati sensibili. Qui puoi leggere la nostra <a target="_blank" href="/privacy" >Norme sulla privacy e sulla sicurezza.</a><br><br><span style="font-size: 20px; font-weight: bold;" > Dove avviene il pagamento tra datore di lavoro e dipendente?</span><br>Il pagamento come discussione del lavoro tra datore di lavoro e dipendente avviene al di fuori della piattaforma.',
    how_it_works_title: "Come funziona",
    how_it_works_body: '<span style="font-size: 18px;" >È molto semplice.</span><br><br>Se sei un <b>dipendente</b> in cerca di lavoro, registrati sulla nostra piattaforma, vai al tuo profilo , inserisci tutte le tue informazioni e attendi che un datore di lavoro ti contatti.<br><br>Se sei un <b>datore di lavoro</b> alla ricerca di un dipendente, registrati nella nostra piattaforma, compila i dati del tuo profilo e cerca un dipendente. Ricorda, se non vedi il lavoro che stai cercando nell\'elenco a discesa della nostra barra di ricerca significa che non abbiamo ancora nessun utente con quel lavoro.',
    name_error: "Scrivi un nome valido.",
    email_error: "Scrivi una e-mail valida.",
    password_not_valid: "La password deve essere lunga almeno 8 caratteri e includere almeno un MAIUSCOLO, un minuscolo e un carattere speciale.",
    password_different: "Le password sono diverse.",
    job_error: "Scrivi un lavoro valido.",
    company_name_error: "Scrivi un nome di azienda valido.",
    company_role_error: "Scrivi un ruolo di aziendale valido.",
    welcome_message: "Benvenuto a Hephaestus!",
    something_wrong: "Qualcosa è andato storto, riprova più tardi.",
    used_email: "Questa e-mail è già in uso.",
    not_active_user: "Questo utente è disattivato, per riattivare questo account contattare: egel.development@gmail.com",
    not_signed_user: "Questo utente non è registrato nel sistema.",
    wrong_credentials: "Credenziali sbagliate.",
    invalid_credentials: "Credenziali non valide.",
    login: "Accedi",
    email_address: "Indirizzo email",
    password: "Password",
    click_here: "Clicca qui",
    full_name: "Nome e cognome",
    repeat_password: "Ripeti la password",
    employee: "Dipendente",
    employer: "Datore di lavoro",
    choose_a_job: "Lavoro (scegli uno dalla lista o scrivine uno nuovo)",
    company_name: "Nome della ditta",
    company_role: "Il mio ruolo in azienda",
    signing_accept: "Effettuando l'accesso accetto il",
    privacy_and_security: "Politica sulla Privacy e Sicurezza.",
    sign_out: "Disconnessione",
    edit_profile_photo: "Modifica la foto del profilo",
    edit_prfile: "Modifica Profilo",
    edit_work_experience: "Modifica esperienza lavorativa",
    settings: "Impostazioni",
    general_info: "Informazione generale",
    email: "E-mail",
    country: "Paese",
    city: "Città",
    userID: "ID utente",
    price_x_hour: "Prezzo per ora",
    work_experience: "Esperienza lavorativa",
    price: "Prezzo",
    currency: "Valuta",
    save: "Salva",
    change_email: "Cambia email",
    change_password: "Cambia la password",
    deactivate_account: "Disattiva Account",
    new_email: "Nuova email",
    work_experience_update_success: "Esperienza lavorativa aggiornata con successo.",
    work_experience_update_error: "L\'esperienza lavorativa non può essere nulla.",
    company_name_empty_error: "Il nome dell\'azienda non può essere vuoto.",
    company_role_empty_error: "Il tuo ruolo nell\'azienda non può essere vuoto.",
    name_empty_error: "Il nome completo non può essere vuoto.",
    country_empty_error: "Il paese non può essere vuoto.",
    city_empty_error: "La città non può essere vuota.",
    user_info_update_success: "Informazioni utente aggiornate con successo.",
    image_empty_error: "È necessario selezionare un'immagine per continuare.",
    image_update_success: "Foto del profilo aggiornata con successo!",
    change_email_error: "Controlla la tua password o la nuova e-mail.",
    email_update_success: "E-mail aggiornata con successo.",
    password_reset_sent: "Le istruzioni per modificare la password sono state inviate alla tua e-mail.",
    account_deactivation_message: "Il tuo account è stato disattivato, per riattivarlo contattaci su: egel.development@gmail.com",
    contact: "Contatta",
    filters: "Filtri",
    max_price_x_hour: "Max. prezzo per ora",
    no_worker_params: "Nessun lavoratore con i parametri selezionati.",
    no_params_no_search: "Parametri di ricerca non validi.",
    search_employee: "Cerca lavoratori",
    view_job_offers: "Opportunità di lavoro",
    sign_up: "Registrati",
    still_no_account: 'Ancora non hai il tuo account?',
    programmer: 'Programmatore',
    same_email: 'E-mail uguali.',
    forgot_password: 'Ho dimenticato la mia password.',
    forgot_pass_email: 'Scrivi l\'email del tuo account.',
    job: 'Lavoro',
    login_first: 'Per contattare un lavoratore devi entrare nel tuo account o crearne uno.',
    home: 'Home',
    download_app: 'Scarica la nostra APP.',
    download_app_body: 'Scarica la nostra APP disponibile su Google Play e Apple Store.',
    telephone: 'Telefono',
    telephone_empty_error: 'Il numero di telefono no puo essere vuoto.',
    do_contact: 'Vuoi contattare a ',
    contact_email: 'Puoi contattare via email',
    contact_telephone: 'Puoi contattare per telefono',
    min_price_month: 'Min. salario mensile',
    new_offers: 'Nuove offerte',
    offer_title: 'Titolo offerta',
    job_descriptio: 'Descrizione del lavoro',
    offer_succees: 'Offerta publicata!',
    salary_month: 'Slario mensile',
    salary_empty_error: 'Il salario non può essere vuoto.',
    job_title_empty_error: 'Il titolo non può essere vuoto.',
    job_name_empty_error: 'Il lavoro non può essere vuoto.',
    job_description_empty_error: 'La descrizione del lavoro non può essere vuota.',
    contact_email: 'E-mail di contatto',
    contact_phone: 'Telefono di contatto',
    published_on: 'Pubblicato il',
    publisher: 'Creatore',
    location: 'Localizazione',
    no_job_offer_match: 'Non ci sono offerte secondo i tuoi filtri.',
    edit_offer: 'Cambia offerta',
    visibility: 'Visibilita',
    visible: 'Visibile',
    hidden: 'Nascosto',
    offer_updated: 'Offerta atualizata!',
    create_offer: 'Crea offerta',
    tutorial: 'Tutoriale'
}

const spanish = {
    who_we_are_title: "Quienes somos",
    who_we_are_body: "Hephaestus es un portal de empleo en línea creado para que los empleadores encuentren al empleado adecuado para sus necesidades.<br><br>Para el <b> empleador </b>, Hephaestus brinda acceso inmediato a miles de empleados con habilidades específicas, sin la necesidad de colocar un puesto de trabajo. anuncios. <br><br>Para el <b> empleado </b>, Hephaestus ofrece una fuente constante de oportunidades de trabajo a tiempo parcial o completo, sin los problemas y gastos de publicidad y autopromoción. Solo necesita completar su perfil y esperar a que los empleadores se comuniquen con usted.<br><br>Hephaestus lucha por brindar un lugar seguro y simple para que los empleadores encuentren empleados en todo el mundo. Únase a nuestra creciente familia para maximizar sus ganancias.",
    FAQ_title: "FAQ",
    FAQ_body: '<span style="font-size: 20px; font-weight: bold;"> ¿Es gratis? </span><br>Sí, pero en el futuro, con las mejoras de la página, podríamos cobrarle algo al empleador por comunicarse con los empleados.<br><br><span style="font-size: 20px; font-weight: bold;"> ¿Están mis datos seguros? </span><br>Sí, sus datos están completamente seguros aunque no almacenamos datos confidenciales. Aquí puede leer nuestra <a target="_blank" href="/privacy"> Política de privacidad y seguridad. </a><br><br><span style="font-size: 20px; font-weight: bold;"> ¿Dónde ocurre el pago entre el empleador y el empleado? </span><br>El pago como la discusión del trabajo entre el empleador y el empleado ocurre fuera de la plataforma.',
    how_it_works_title: "Cómo funciona",
    how_it_works_body: '<span style="font-size: 18px;" > Es muy fácil. </span><br><br>Si eres un <b> empleado </b> que busca trabajo, simplemente regístrate en nuestra plataforma, ve a tu perfil , complete toda su información y espere a que un empleador se comunique con usted.<br><br>Si es un <b> empleador </b> que busca un empleado, regístrese en nuestra plataforma, complete los datos de su perfil y busque un empleado. Recuerde, si no ve el trabajo que está buscando en nuestra lista desplegable de la barra de búsqueda, significa que todavía no tenemos ningún usuario con ese trabajo.',
    name_error: "Escribe un nombre válido.",
    email_error: "Escribe un e-mail válido.",
    password_not_valid: "La contraseña debe tener al menos 8 caracteres e incluir al menos una MAYÚSCULA, una minúscula y un carácter especial.",
    password_different: "Las contraseñas son diferentes.",
    job_error: "Escribe un trabajo válido.",
    company_name_error: "Escribe un nombre de empresa válido.",
    company_role_error: "Escribe un rol en la empresa válido.",
    welcome_message: "Bienvenido a Hephaestus!",
    something_wrong: "Algo ha salido mal, inténtalo más tarde.",
    used_email: "Este e-mail ya está en uso.",
    not_active_user: "Este usuario está desactivado, para reactivar esta cuenta contacte: egel.development@gmail.com",
    not_signed_user: "Este usuario no está registrado en el sistema.",
    wrong_credentials: "Credenciales erróneas.",
    invalid_credentials: "Credenciales no válidas.",
    login: "Entrar",
    email_address: "Correo electrónico",
    password: "Contraseña",
    click_here: "Click aquí",
    full_name: "Nombre completo",
    repeat_password: "Repite la contraseña",
    employee: "Empleado",
    employer: "Empleador",
    choose_a_job: "Trabajo (elija uno de la lista o escriba uno nuevo)",
    company_name: "Nombre de la empresa",
    company_role: "Mi rol en la empresa",
    signing_accept: "Al iniciar sesión, acepto el",
    privacy_and_security: "Política de Privacidad y Seguridad.",
    sign_out: "Cerrar sesión",
    edit_profile_photo: "Editar foto de perfil",
    edit_prfile: "Editar perfil",
    edit_work_experience: "Editar experiencia de trabajo",
    settings: "Ajustes",
    general_info: "Información general",
    email: "E-mail",
    country: "País",
    city: "Ciudad",
    userID: "ID usuario",
    price_x_hour: "Precio por hora",
    work_experience: "Experiencia de trabajo",
    price: "Precio",
    currency: "Moneda",
    save: "Guardar",
    change_email: "Cambiar correo electrónico",
    change_password: "Cambiar contraseña",
    deactivate_account: "Desactivar cuenta",
    new_email: "Nuevo correo electrónico",
    work_experience_update_success: "Experiencia de trabajo actualizada con éxito.",
    work_experience_update_error: "La experiencia de trabajo no puede estar vacía.",
    company_name_empty_error: "El nombre de la empresa no puede estar vacío.",
    company_role_empty_error: "Tu rol en la empresa no puede estar vacío.",
    name_empty_error: "El nombre completo no puede estar vacío.",
    country_empty_error: "El país no puede estar vacío.",
    city_empty_error: "La ciudad no puede estar vacía.",
    user_info_update_success: "La información del usuario se actualizó correctamente.",
    image_empty_error: "Se debe seleccionar una imagen para continuar.",
    image_update_success: "¡La foto de perfil se actualizó correctamente!",
    change_email_error: "Verifique su contraseña o correo electrónico nuevo.",
    email_update_success: "Correo electrónico actualizado correctamente.",
    password_reset_sent: "Las instrucciones para cambiar su contraseña se han enviado a su correo electrónico.",
    account_deactivation_message: "Su cuenta ha sido desactivada, para reactivarla por favor contáctenos en: egel.development@gmail.com",
    contact: "Contactar",
    filters: "Filtros",
    max_price_x_hour: "Max. precio por hora",
    no_worker_params: "No hay ningún trabajador con los parámetros seleccionados.",
    no_params_no_search: "Parámetros de búsquedad incorrectos.",
    search_employee: "Buscar empleados",
    view_job_offers: "Ofertas de trabajo",
    sign_up: "Registrate",
    still_no_account: '¿Aún no tienes una cuenta?',
    programmer: 'Programador',
    same_email: 'E-mails iguales.',
    forgot_password: 'He olvidado mi contraseña.',
    forgot_pass_email: 'Escribe el email de tu cuenta.',
    job: 'Trabajo',
    login_first: 'Para contactar con un trabajador debes entrar en tu cuenta o crear una.',
    home: 'Home',
    download_app: 'Descarga nuestra APP.',
    download_app_body: 'Descarga nuestra APP disponible en Google Play y Apple Store.',
    telephone: 'Teléfono',
    telephone_empty_error: 'El número de teléfono no puede estar vacío.',
    do_contact: '¿Quieres contactar a ',
    contact_email: 'Puedes contactar por email',
    contact_telephone: 'Puedes contactar por teléfono',
    min_price_month: 'Min. salario mensual',
    new_offers: 'Nuevas ofertas',
    offer_title: 'Título de la oferta',
    job_descriptio: 'Descripción del trabajo',
    offer_succees: '¡Oferta publicada con éxito!',
    salary_month: 'Salario mensual',
    salary_empty_error: 'El salario no puede estar vacío.',
    job_title_empty_error: 'El título non puede estar vacío.',
    job_name_empty_error: 'El trabajo no puede estar vacío.',
    job_description_empty_error: 'La descripción del trabajo no puede estar vacía.',
    contact_email: 'Correo electrónico de contacto',
    contact_phone: 'Teléfono de contacto',
    published_on: 'Publicado el',
    publisher: 'Creador',
    location: 'Localización',
    no_job_offer_match: 'No hay ofertas según tu búsquedad.',
    edit_offer: 'Editar oferta',
    visibility: 'Visibilidad',
    visible: 'Visible',
    hidden: 'Oculto',
    offer_updated: '¡Oferta actualizada con éxito!',
    create_offer: 'Crear oferta',
    tutorial: 'Tutorial'
}

const localized_strings = {
    english: english,
    italian: italian,
    spanish: spanish
}

export default localized_strings