import React from "react";

const AndroidButton = () => {
    return<>
    <a className="btn android-button" target='_blank' href="https://play.google.com/store/apps/details?id=com.egel.hephaestus">
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex', marginRight: 10}}>
                <i className="fab fa-android" style={{fontSize: 40}}></i>
            </div>
            <div style={{ flex: 1, alignItems: 'flex-start', display: 'flex', marginLeft: 10}}>
                <p className="my-auto" >Google Play</p>
            </div>
        </div>
    </a>
    </>
}

export default AndroidButton