import React from "react";

const SocialButton = ({url, icon}) => {

    return<>
        <a rel="noreferrer" className='btn footer-buttons' target='_blank' href={url} >
            <i className={icon}></i>
        </a>
    </>
}

export default SocialButton