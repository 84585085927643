import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebaseVariables from "../../firebase.config";
import { get_localized_string, LoadingModal, log_error } from "../../HelperFunctions";
import Banner from "../../ReusableComponents/Banner";
import { colors } from "../../ReusableComponents/Colors";
import Input from "../../ReusableComponents/Input";
import Label from "../../ReusableComponents/Label";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import OrangeButton from "../../ReusableComponents/OrangeButton";
import Separator from "../../ReusableComponents/Separator";

const EditProfileEmployer = ({onClose = () => {}, userInfo}) => {

    const localized_strings = useState(get_localized_string())

    const [isLoadingOpen, setLoadingOpen] = useState(false)

    const [nameError, setNameError] = useState(false)
    const [telephoneError, setTelefoneError] = useState(false)
    const [countryError, setCountryError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [companyNameError, setCompanyNameError] = useState(false)
    const [companyRoleError, setcompanyRoleError] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [errorVisible, setErrorVisible] = useState(false)
    const handleBanner = (message, type, duration) => {
        setErrorMessage(message)
        setErrorType(type)
        setErrorVisible(true)
        setTimeout(() => {
            setErrorVisible(false)
        }, duration)
    }

    const handleChange = () => {
        setNameError(false)
        setTelefoneError(false)
        setCountryError(false)
        setCityError(false)
        setCompanyNameError(false)
        setcompanyRoleError(false)

        const name = document.getElementById('nameInput').value.toString().trim()
        const tel = document.getElementById('telephoneInput').value.toString().trim()
        const country = document.getElementById('countryInput').value.toString().trim()
        const city = document.getElementById('cityInput').value.toString().trim()
        const companyName = document.getElementById('companyNameInput').value.toString().trim()
        const companyRole = document.getElementById('companyRoleInput').value.toString().trim()

        if(name==='') {
            setNameError(true)
            handleBanner(localized_strings[0].name_empty_error, 'error', 3000)
            return
        }

        if(tel==='') {
            setTelefoneError(true)
            handleBanner(localized_strings[0].telephone_empty_error, 'error', 3000)
            return
        }

        if(country==='') {
            setCountryError(true)
            handleBanner(localized_strings[0].country_empty_error, 'error', 3000)
            return
        }

        if(city==='') {
            setCityError(true)
            handleBanner(localized_strings[0].city_empty_error, 'error', 3000)
            return
        }

        if(companyName==='') {
            setCompanyNameError(true)
            handleBanner(localized_strings[0].company_name_empty_error, 'error', 3000)
            return
        }

        if(companyRole==='') {
            setcompanyRoleError(true)
            handleBanner(localized_strings[0].company_role_empty_error, 'error', 3000)
            return
        }

        setLoadingOpen(true)
        const db_reference = doc(firebaseVariables.firestoreApp, firebaseVariables.firestorePath.concat('/db/users'), firebaseVariables.firebaseAuth.currentUser.uid.toString())
        updateDoc(db_reference, {
            name: name,
            country: country, 
            city: city,
            companyName: companyName,
            companyRole: companyRole,
            telephone: tel
        })
        .then(() => {
            
            setLoadingOpen(false)
            handleBanner(localized_strings[0].user_info_update_success, 'success', 3000)
            setTimeout(() => {
                window.location.reload()
            }, 2000);

        }).catch((error) => {
            log_error(error.code, error.message)
            setLoadingOpen(false)
            handleBanner(localized_strings[0].something_wrong, 'error', 3000)
        })
    }

    useEffect(() => {
        document.getElementById('nameInput').value = userInfo.name
        document.getElementById('telephoneInput').value = userInfo.telephone===undefined?'':userInfo.telephone
        document.getElementById('countryInput').value = userInfo.country
        document.getElementById('cityInput').value = userInfo.city
        document.getElementById('companyNameInput').value = userInfo.companyName
        document.getElementById('companyRoleInput').value = userInfo.companyRole
    }, [])

    return<>
        <Banner message={errorMessage} type={errorType} isVisible={errorVisible} />
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
            <ModalHeader title={localized_strings[0].edit_prfile} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start', paddingLeft: 20, paddingRight: 20}}>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].full_name + ':'}/>
            <Input id='nameInput' onError={nameError}/>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].telephone + ':'}/>
            <Input id='telephoneInput' onError={telephoneError}/>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].country + ':'}/>
            <Input id='countryInput' onError={countryError}/>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].city + ':'}/>
            <Input id='cityInput' onError={cityError}/>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].company_name + ':'}/>
            <Input id='companyNameInput' onError={companyNameError}/>
            <Separator size='SMALL' />

            <Label title={localized_strings[0].company_role + ':'}/>
            <Input id='companyRoleInput' onError={companyRoleError}/>
            <Separator size='SMALL' />

            <OrangeButton title={localized_strings[0].save} onClick={handleChange} />

            <Separator size='SMALL' />

        </div>
        <LoadingModal open={isLoadingOpen}/>
    </>
}

export default EditProfileEmployer