import React from "react";
import { colors } from "./Colors";

const Input = ({type = 'text', id, autoCapitalize = 'words', onError, disbled = false, placeholder ='', onChange = () => {}}) => {

    return<>
        <input type={type} className="form-control" id={id} autoCapitalize={autoCapitalize} style={{ borderColor: onError?'red': colors.lightGray }} disabled={disbled} placeholder={placeholder} onChange={onChange} />
    </>
}

export default Input