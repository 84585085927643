import React, { useState } from "react";
import { get_localized_string, User } from "../../HelperFunctions";
import ImageView from "../../ReusableComponents/ImageView";
import ModalComponent from "../../ReusableComponents/ModalComponent";
import ChangeEmailModal from "./ChangeEmailModal";
import ControlButtons from "./ControlButtons";
import EditPhotoModal from "./EditPhotoModal";
import EditProfileEmployee from "./EditProfileEmployee";
import EditProfileEmployer from "./EditProfileEmployer";
import EditWorkExperienceModal from "./EditWorkExperienceModal";
import SettingsModal from "./SettingsModal";

const UserControl = ({userInfo = new User()}) => {

    const localized_strings = useState(get_localized_string())
    const [settingVisible, setSettingVisible] = useState(false)
    const [emailModalOpen, setEmailModalOpen] = useState(false)
    const [workExperienceModalOpen, setWorkExperienceModalOpen] = useState(false)
    const [editProfileModalOpen, setEditProfileModalOpen] = useState(false)
    const [editProfilePicModal, setEditProfilePicModal] = useState(false)

    const editProfileView = () => {
        if (userInfo.userType === 'employee') {
            return <EditProfileEmployee onClose={() => {setEditProfileModalOpen(false)}} userInfo={userInfo}/>
        }
        return <EditProfileEmployer onClose={() => {setEditProfileModalOpen(false)}} userInfo={userInfo}/>
    }

    return<>
        <div className="container-fluid" >
            <div className="row">
                <div className="col">
                    <ImageView imageURL={userInfo.profilePicURL} cornerRadius={20} size="BIG" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ControlButtons title={localized_strings[0].edit_profile_photo} onClick={() => {setEditProfilePicModal(true)}} />
                    <ControlButtons title={localized_strings[0].edit_prfile} onClick={() => {setEditProfileModalOpen(true)}} />
                    <ControlButtons title={localized_strings[0].edit_work_experience} onClick={() => {setWorkExperienceModalOpen(true)}} />
                    <ControlButtons title={localized_strings[0].settings} onClick={() => {setSettingVisible(true)}} />
                </div>
            </div>
        </div>
        <ModalComponent visibility={settingVisible} view={<SettingsModal onClose={()=>{setSettingVisible(false)}} openCE={() => {setEmailModalOpen(true)}} />} />
        <ModalComponent visibility={emailModalOpen} view={<ChangeEmailModal onClose={()=>{setEmailModalOpen(false)}}/>} />
        <ModalComponent visibility={workExperienceModalOpen} view={<EditWorkExperienceModal onClose={() => {setWorkExperienceModalOpen(false)}} userInfo={userInfo} />}/>
        <ModalComponent visibility={editProfileModalOpen} view={editProfileView()}/>
        <ModalComponent visibility={editProfilePicModal} view={<EditPhotoModal onClose={() => {setEditProfilePicModal(false)}} userInfo={userInfo}/>}/>
    </>
}

export default UserControl