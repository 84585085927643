import React from "react";

const Separator = ({size}) => {

    const height = () => {
        switch (size) {
            case 'SMALL':
                return 25;
            case 'MEDIUM':
                return 50;
            case 'BIG':
                return 100;
            default:
                return 15;
        }
    }

    return<>
        <div style={{width: 1, height: height()}} ></div>
    </>
}

export default Separator