import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ControlButtons from "../../Admin/components/ControlButtons";
import { get_localized_string } from "../../HelperFunctions";
import { colors } from "../../ReusableComponents/Colors";
import ModalHeader from "../../ReusableComponents/ModaleHeader";
import Separator from "../../ReusableComponents/Separator";
import Body from "../../ReusableComponents/Body"

const LoginRegisterModal = ({onClose = () => {}}) => {

    const history = useHistory()
    const localized_strings = useState(get_localized_string())

    const handleLogin = () => history.push('/home')
    const handleRegister = () => history.push('/register')

    return<>
        <div style={{ width: '100%', backgroundColor: colors.blue, borderTopRightRadius: 10, borderTopLeftRadius: 10 }} >
            <ModalHeader title={localized_strings[0].contact} onClose={onClose} />
        </div>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'start'}}>
                <Separator size='SMALL' />
            <div style={{ marginLeft: 10, marginRight: 10}} >
                <Body text={localized_strings[0].login_first} />
            </div>
            <ControlButtons title={localized_strings[0].login} onClick={handleLogin} />
            <ControlButtons title={localized_strings[0].sign_up} onClick={handleRegister} />
            <Separator size='SMALL' />
        </div>
    </>
}

export default LoginRegisterModal