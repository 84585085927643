import React, { useState } from "react";
import { colors } from "../../ReusableComponents/Colors";
import DownloadApp from "./DownloadApp";
import FAQ from "./FAQ";
import HowWorks from "./HowWorks";
import Video from "./Video";
import WhoWeAre from "./WhoWeAre";

const ShowCard = () => {

    const [selectedView, setSelectedView] = useState(0)

    const renderView = () => {
        switch (selectedView) {
            case 0:
                return <Video url="ui-BY6bAlL8"/>;
            case 1:
                return <WhoWeAre/>;
            case 2:
                return <FAQ/>;
            case 3:
                return <HowWorks/>;
            case 4:
                return <DownloadApp/>;
            case 5:
                return <Video url="Ce04U7j7yCw"/>;
            case 6:
                return <Video url="mxbNbdZpzXE"/>;
            case 7:
                return <Video url="RGCgF_Nn6as"/>;
            default:
                setSelectedView(0)
                break;
        }
    }

    const handleLeft = () => {
        if (selectedView===0) {
            setSelectedView(7)
            return
        }
        if (selectedView===1) {
            setSelectedView(0)
            return
        }
        if (selectedView===2) {
            setSelectedView(1)
            return
        }
        if (selectedView===3) {
            setSelectedView(2)
            return
        }
        if (selectedView===4) {
            setSelectedView(3)
            return
        }
        if (selectedView===5) {
            setSelectedView(4)
            return
        }
        if (selectedView===6) {
            setSelectedView(5)
            return
        }
        if (selectedView===7) {
            setSelectedView(6)
            return
        }
    }

    const handleRight = () => {
        if (selectedView===0) {
            setSelectedView(1)
            return
        }
        if (selectedView===1) {
            setSelectedView(2)
            return
        }
        if (selectedView===2) {
            setSelectedView(3)
            return
        }
        if (selectedView===3) {
            setSelectedView(4)
            return
        }
        if (selectedView===4) {
            setSelectedView(5)
            return
        }
        if (selectedView===5) {
            setSelectedView(6)
            return
        }
        if (selectedView===6) {
            setSelectedView(7)
            return
        }
        if (selectedView===7) {
            setSelectedView(0)
            return
        }
    }

    return<>
        <div className="round_corner_container_semitransparent row" style={{ height: '100%'}}>
            <div className="col-1" style={{ display: 'flex', flexDirection: 'column' }}>
                <button type="button" className="btn my-auto" style={{ color: colors.orange }} onClick={handleLeft} >
                    <i className="fas fa-chevron-left" style={{ fontSize: 26 }} ></i>
                </button>
            </div>
            <div className="col" style={{ display: 'flex' }}>
                {renderView()}
            </div>
            <div className="col-1" style={{ display: 'flex', flexDirection: 'column' }}>
                <button type="button" className="btn my-auto" style={{ color: colors.orange }} onClick={handleRight} >
                    <i className="fas fa-chevron-right" style={{ fontSize: 26 }} ></i>
                </button>
            </div>
        </div>
    </>
}

export default ShowCard