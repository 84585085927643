import { TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab'
import React from "react";

const CustomAutocomplete = ({list = [], id, placeholder = ''}) => {
    return<>
        <Autocomplete 
            freeSolo={true}
            options={list}
            id={id}
            renderInput={(params) =>
                <TextField {...params} className="form-control" autoCapitalize='words' placeholder={placeholder}
                    style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}
                    InputProps={{...params.InputProps}} />} />
</>
}

export default CustomAutocomplete